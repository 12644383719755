import { apiConfig } from "../../config/Config.js";
import { apiEndPoint, apiType, apiStatus } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
import {
  SC_COMPANY_ID,
  SC_DIVISION_ID,
  SC_GROUP_ID,
  SC_LOGIN_USER_ID,
  SC_MODULE_ID,
} from "../../constant/StorageConstant.js";
import userSessionController from "../UserSession/UserSessionController.js";

let Report = {
 
  
  async getUserListReport() {
    var companyId = userSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId = userSessionController.getLocalSessionValue(SC_DIVISION_ID);

    if (companyId.length > 0 && divisionId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionId,
        type: "USER",
        offset: 0,
        limit: 100,
        category: "USER",
        status: "ACTIVE",
      };

      // console.log("myuserspayload: " + JSON.stringify(reqPayload))
      return await ApiService.callGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.USER,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },  
  
  
 
};

export default Report;