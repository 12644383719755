// import { Document, Image, Line, Page, PDFViewer, Rect, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
// import React from 'react'
// import PdfHeader from '../Components/pdfHeader';
// import PdfHeading from '../Components/pdfHeading';
// import PdfFooter from '../Components/pdfFooter';
// import { useLocation } from 'react-router-dom';
// import UtilDateTime from '../../../util/UtilDateTime';
// import ValueFormat from '../../../util/ValueFormat';

// const styles = StyleSheet.create({
//     pdfViewer: {
//         height: "96%",
//         width: "100%",
//         marginTop: "20px",
//         marginBottom: "5px",
//     },
//     document: {
//         marginTop: "15px",
//         marginBottom: "5px",
//     },
//     page: {
//         width: "100%",
//     },
//     viewWithColor: {
//         backgroundColor: "#615AE41A",
//         marginTop: 10,
//         marginBottom: 0,
//         padding: 15,
//         paddingTop: 10,
//         borderRadius: 10
//     },
//     heading: {
//         fontSize: 11,
//         marginBottom: 5
//     }
// });

// const fontBold = {
//     fontFamily: "Helvetica-Bold",
// };

// const ColouredriskAlertSummaryPdf = ({
//     riskAlertPdf,
//     aCBDataProps,
//     rccProps,
//     osMcuMsuProps,
//     aCBProps,
//     osDataProps,
//     msuPdfDataProps,
//     mcuPdfDataProps,
//     section2filter,
// }) => {
// console.log("riskAlertPdf",riskAlertPdf);
//     const pathname = useLocation();
//     var totalPages = 0;
//     const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

//     let period = "";
//     let startDate = "";
//     let endDate = "";

//     if (section2filter.durationFilter === "CURRENT_YEAR") {
//         period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
//         startDate = UtilDateTime.getCurrentYear(section2filter.startDateTime);
//         endDate = "";
//     } else if (section2filter.durationFilter === "CURRENT_MONTH") {
//         period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
//         startDate = UtilDateTime.getCurrentMonth(section2filter.startDateTime);
//         endDate = "";
//     } else if (section2filter.durationFilter === "CURRENT_WEEK") {
//         period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
//         startDate = UtilDateTime.getCurrentWeek1(section2filter.startDateTime);
//         endDate = "";
//     } else if (section2filter.durationFilter === "CURRENT_DAY") {
//         period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
//         startDate = UtilDateTime.getCurrentDay(section2filter.startDateTime);
//         endDate = "";
//     } else {
//         period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
//         startDate = section2filter.startDateTime.split(" ")[0];
//         endDate = section2filter.endDateTime.split(" ")[0];
//     }

//     const periodFormat = [period, startDate, endDate];
//     var currentDate = new Date();
//     const heading = "User Dashboard (Risk Alert Summary)";
//     const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

//     if (!osMcuMsuProps || osMcuMsuProps.length < 3) {
//         // console.error("osMcuMsuProps is undefined or has less than 3 elements");
//         return null;
//     }

//     const overSpeed = osMcuMsuProps[0].toString();
//     const mobileCallUse = osMcuMsuProps[1].toString();
//     const mobileScreenUse = osMcuMsuProps[2].toString();

//     const originalMax = Math.max(
//         parseFloat(overSpeed),
//         parseFloat(mobileCallUse),
//         parseFloat(mobileScreenUse)
//     );

//     const convertToHeight = (values, newMax) => {
//         return values.map((value) => {
//             const numericValue = parseFloat(value);
//             // console.log("Numeric value:", numericValue);
//             if (!isNaN(numericValue)) {
//                 const originalMin = 0;
//                 const newMin = 0;
//                 let mappedValue =
//                     ((numericValue - originalMin) / (originalMax - originalMin)) *
//                     (newMax - newMin) +
//                     newMin;
//                 mappedValue = Math.min(Math.max(mappedValue, newMin), newMax);
//                 mappedValue *= -1;
//                 return mappedValue;
//             } else {
//                 console.log("Error: Value is not a number");
//                 return 0;
//             }
//         });
//     };

//     const labels = ["Overspeed", "Mobile Call Use", "Mobile Screen Use"];
//     const values = [overSpeed, mobileCallUse, mobileScreenUse];
//     const heights = convertToHeight(values, 135);

//     const data1 = values.map((value, index) => ({
//         // x: 26 + index * (100 / values.length) + 56,   //this is for if in future they change it to four values or 1 values
//         x: 26 + index * 78,
//         y: 159.5,
//         width: "10",
//         height: heights[index],
//         fill: "rgb(84, 112, 198)",
//         text: value,
//         label: labels[index],
//     }));

//     const lmh = {
//         low: ValueFormat.parseAsString(aCBProps.low),
//         medium: ValueFormat.parseAsString(aCBProps.medium),
//         high: ValueFormat.parseAsString(aCBProps.high),
//     };

//     const maxLMHValue = Math.max(
//         ...Object.values(lmh)
//             .flat()
//             .map((value) => parseInt(value, 10))
//     );

//     const generateLMHData = () => {
//         const lmhData = [];
//         const severityOrder = ["low", "medium", "high"];

//         for (let setIndex = 0; setIndex < 3; setIndex++) {
//             severityOrder.forEach((severity, severityIndex) => {
//                 const value = lmh[severity][setIndex];
//                 const x = 28 + setIndex * (17 * 3 + 20) + severityIndex * 17;
//                 const y = 179.5;
//                 const label = value;
//                 const height = -(value / maxLMHValue) * 135;
//                 let color = "rgb(54, 115, 53)";

//                 if (severity === "medium") {
//                     color = "rgb(251, 191, 0)";
//                 } else if (severity === "high") {
//                     color = "rgb(255, 0, 0)";
//                 }

//                 lmhData.push({ x, y, label, height, color });
//             });
//         }

//         return lmhData;
//     };

//     const lmhData = generateLMHData();

//     const totalRiskAlertDataLength = riskAlertPdf?.length;
//     const displayRisAlertRange =
//         totalRiskAlertDataLength > 0
//             ? `Showing 1 - ${Math.min(
//                 6,
//                 totalRiskAlertDataLength
//             )} out of ${totalRiskAlertDataLength}`
//             : "Showing 0 out of 0";
//     const columns = ["tripIcon", "alertImage", "rideName", "date", "speed"];
//     const columnDisplayNameMap = {
//         tripIcon: "Trip Icon",
//         alertImage: "Alert Image",
//         rideName: "Ride Name",
//         date: "Date/Time",
//         speed: "Speed (km/hr)",
//     };

//     const totalACBDataLength = aCBDataProps?.length;
//     const displayACBDataRange =
//         totalACBDataLength > 0
//             ? `Showing 1 - ${Math.min(
//                 6,
//                 totalACBDataLength
//             )} out of ${totalACBDataLength}`
//             : "Showing 0 out of 0";

//     const ACBColumn = ["rideName", "date", "type", "subCategoryLevel", "speed"];
//     const columnDisplayNameMap1 = {
//         rideName: "Ride Name",
//         date: "Date/Time",
//         type: "Type",
//         subCategoryLevel: "Subtype",
//         speed: "Speed (km/hr)",
//     };

//     const columns1 = ["rideName", "date", "speedLimit", "speed"];
//     const columnDisplayNameMap2 = {
//         rideName: "Ride Name",
//         date: "Date/Time",
//         speedLimit: "Speed Limit",
//         speed: "Speed (km/hr)",
//     };
//     const columns2 = ["rideName", "date", "speed"];
//     const columnDisplayNameMap3 = {
//         rideName: "Ride Name",
//         date: "Date/Time",
//         speed: "Speed (km/hr)",
//     };

//     const totalOSDataLength = osDataProps?.length;
//     const displayOSDataRange =
//         totalOSDataLength > 0
//             ? `Showing 1 - ${Math.min(
//                 6,
//                 totalOSDataLength
//             )} out of ${totalOSDataLength}`
//             : "Showing 0 out of 0";

//     const totalMSUDataLength = msuPdfDataProps?.length;
//     const displayMSUDataRange =
//         totalMSUDataLength > 0
//             ? `Showing 1 - ${Math.min(
//                 6,
//                 totalMSUDataLength
//             )} out of ${totalMSUDataLength}`
//             : "Showing 0 out of 0";

//     const totalMCUDataLength = mcuPdfDataProps?.length;
//     const displayMCUDataRange =
//         totalMCUDataLength > 0
//             ? `Showing 1 - ${Math.min(
//                 6,
//                 totalMCUDataLength
//             )} out of ${totalMCUDataLength}`
//             : "Showing 0 out of 0";

//     const maxRowsRA = 4;
//     const maxRows = 6; // Maximum rows to render
//     const aCBDataPropsrowCount = Math.min(aCBDataProps?.length, maxRows);
//     const riskAlertPdfPropsrowCount = Math.min(riskAlertPdf?.length, maxRowsRA);
//     const osDataPropsrowCount = Math.min(osDataProps?.length, maxRows);
//     const msuPdfDataPropsrowCount = Math.min(msuPdfDataProps?.length, maxRows);
//     const mcuPdfDataPropsrowCount = Math.min(mcuPdfDataProps?.length, maxRows);

//     const { data } = rccProps;

//     // Define the static data with titles and corresponding values from rccProps.data
//     const staticData = [
//         { title: "Animal Crossing", value: data[0] },
//         { title: "Caution", value: data[1] },
//         { title: "Curves", value: data[2] },
//         { title: "Hill", value: data[3] },
//         { title: "Hill Downwards", value: data[4] },
//         { title: "Hill Upwards", value: data[5] },
//         { title: "Icy Condition", value: data[6] },
//         { title: "Intersection", value: data[7] },
//         { title: "Lane Merge", value: data[8] },
//         { title: "Low Gear Area", value: data[9] },
//         { title: "Narrow Road", value: data[10] },
//         { title: "No Overtaking", value: data[11] },
//         { title: "Pedestrian Crossing", value: data[12] },
//         { title: "Priority", value: data[13] },
//         { title: "Railway Crossing", value: data[14] },
//         { title: "Risk Grounding", value: data[15] },
//         { title: "Round About", value: data[16] },
//         { title: "School Zone", value: data[17] },
//         { title: "Slippery Road", value: data[18] },
//         { title: "Stop Sign", value: data[19] },
//         { title: "Traffic Light", value: data[20] },
//         { title: "Wind", value: data[21] },
//         { title: "Winding Road", value: data[22] },
//         { title: "Yield", value: data[23] },
//     ];
//     const table1 = staticData.slice(0, 8);
//     const table2 = staticData.slice(8, 16);
//     const table3 = staticData.slice(16);

//     const changeableheight = "25px";

//     return (
//         <React.Fragment>
//             <PDFViewer style={styles.pdfViewer}>
//                 <Document style={styles.document}>

//                     <Page totalPages={totalPages++} style={styles.page}>

//                         <PdfHeader />
//                         <View style={{ paddingHorizontal: 30, marginTop: 10, marginBottom: 10 }}>
//                             <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
//                             <View style={styles.viewWithColor}>
//                                 <Text style={styles.heading}>1. Risk Alert Count</Text>

//                                 <View style={{ flexDirection: "row", marginBottom: "5px" }}>
//                                     <View
//                                         style={{
//                                             width: "160px",
//                                             border: "1px dashed lightgrey",
//                                             borderBottom: "none",
//                                             marginRight: "27.5px",
//                                             borderLeft: "none",
//                                             borderRight: "none",
//                                         }}
//                                     >
//                                         {table1.map((item, index) => (
//                                             <View
//                                                 key={index}
//                                                 style={{
//                                                     flexDirection: "row",
//                                                     justifyContent: "space-between",
//                                                     fontSize: "9px",
//                                                 }}
//                                             >
//                                                 <View
//                                                     style={{
//                                                         borderLeft: "1px dashed lightgrey",
//                                                         borderBottom: "1px dashed lightgrey",
//                                                         borderRight: "1px dashed lightgrey",
//                                                         width: "100px",
//                                                         padding: "5px",
//                                                     }}
//                                                 >
//                                                     <Text>{item.title}</Text>
//                                                 </View>
//                                                 <View
//                                                     style={{
//                                                         borderBottom: "1px dashed lightgrey",
//                                                         borderRight: "1px dashed lightgrey",
//                                                         width: "60px",
//                                                         textAlign: "right",
//                                                         padding: "5px",
//                                                         paddingRight: '25px'
//                                                     }}
//                                                 >
//                                                     <Text>{item.value}</Text>
//                                                 </View>
//                                             </View>
//                                         ))}
//                                     </View>

//                                     <View
//                                         style={{
//                                             width: "160px",
//                                             border: "1px dashed lightgrey",
//                                             borderBottom: "none",
//                                             marginRight: "27.5px",
//                                             borderLeft: "none",
//                                             borderRight: "none",
//                                         }}
//                                     >
//                                         {table2.map((item, index) => (
//                                             <View
//                                                 key={index}
//                                                 style={{
//                                                     flexDirection: "row",
//                                                     justifyContent: "space-between",
//                                                     fontSize: "9px",
//                                                 }}
//                                             >
//                                                 <View
//                                                     style={{
//                                                         borderLeft: "1px dashed lightgrey",
//                                                         borderBottom: "1px dashed lightgrey",
//                                                         borderRight: "1px dashed lightgrey",
//                                                         width: "100px",
//                                                         padding: "5px",
//                                                     }}
//                                                 >
//                                                     <Text>{item.title}</Text>
//                                                 </View>
//                                                 <View
//                                                     style={{
//                                                         borderBottom: "1px dashed lightgrey",
//                                                         borderRight: "1px dashed lightgrey",
//                                                         width: "60px",
//                                                         textAlign: "right",
//                                                         padding: "5px",
//                                                         paddingRight: '25px'
//                                                     }}
//                                                 >
//                                                     <Text>{item.value}</Text>
//                                                 </View>
//                                             </View>
//                                         ))}
//                                     </View>

//                                     <View
//                                         style={{
//                                             width: "160px",
//                                             border: "1px dashed lightgrey",
//                                             borderBottom: "none",
//                                             borderLeft: "none",
//                                             borderRight: "none",
//                                         }}
//                                     >
//                                         {table3.map((item, index) => (
//                                             <View
//                                                 key={index}
//                                                 style={{
//                                                     flexDirection: "row",
//                                                     justifyContent: "space-between",
//                                                     fontSize: "9px",
//                                                 }}
//                                             >
//                                                 <View
//                                                     style={{
//                                                         borderLeft: "1px dashed lightgrey",
//                                                         borderBottom: "1px dashed lightgrey",
//                                                         borderRight: "1px dashed lightgrey",
//                                                         width: "100px",
//                                                         padding: "5px",
//                                                     }}
//                                                 >
//                                                     <Text>{item.title}</Text>
//                                                 </View>
//                                                 <View
//                                                     style={{
//                                                         borderBottom: "1px dashed lightgrey",
//                                                         borderRight: "1px dashed lightgrey",
//                                                         width: "60px",
//                                                         textAlign: "right",
//                                                         padding: "5px",
//                                                         paddingRight: '25px'
//                                                     }}
//                                                 >
//                                                     <Text>{item.value}</Text>
//                                                 </View>
//                                             </View>
//                                         ))}
//                                     </View>
//                                 </View>
//                             </View>

//                             {/* <View style={styles.viewWithColor}> */}
//                                 <View
//                                     style={{
//                                         width: "100%",
//                                         height: "250.666px",
//                                         // marginBottom: "10px",
//                                         marginTop: "10px",
//                                         flexDirection: "row",
//                                         justifyContent: "space-between",
//                                     }}
//                                 >
//                                     <View style={[styles.viewWithColor,{ width: "49.5%" }]}>
//                                         <View style={{ fontSize: "10px", textAlign: "left",
//                                              marginBottom: '19px'
//                                              }}>
//                                             <Text> 2. Overspeed / Mobile Call Use / Mobile Screen Use </Text>

//                                         </View>
//                                         <Svg
//                                             width="100%"
//                                             height="100%"
//                                         >
//                                             <Line
//                                                 x1="0.5"
//                                                 y1="20"
//                                                 x2="0.5"
//                                                 y2="160"
//                                                 stroke="black"
//                                                 strokeWidth="1"
//                                             />
//                                             <Line
//                                                 x1="0"
//                                                 y1="160"
//                                                 x2="262"
//                                                 y2="160"
//                                                 stroke="black"
//                                                 strokeWidth="1"
//                                             />

//                                             {data1.map((item, index) => (
//                                                 <React.Fragment key={index}>
//                                                     <Rect
//                                                         x={item.x}
//                                                         y={item.y}
//                                                         width={item.width}
//                                                         height={item.height}
//                                                         fill={item.fill}
//                                                     />
//                                                     <Text
//                                                         x={item.x + 5}
//                                                         textAnchor="middle"
//                                                         y={item.y + item.height - 4}
//                                                         fill="black"
//                                                         style={{ fontSize: "10px" }}
//                                                     >
//                                                         {item.text}
//                                                     </Text>
//                                                 </React.Fragment>
//                                             ))}
//                                         </Svg>

//                                         <View
//                                             style={{
//                                                 display: "flex",
//                                                 flexDirection: "row",
//                                                 justifyContent: "space-around",
//                                                 marginTop: "-54px",
//                                             }}
//                                         >
//                                             {data1.map((item, index) => (
//                                                 <Text key={index} style={{ fontSize: "9px" }}>
//                                                     {item.label}
//                                                 </Text>
//                                             ))}
//                                         </View>
//                                         <View><Text style={{ fontSize: "9px", textAlign: 'center', marginTop: '4px' }}>Note: X-axis for Category, Y-axis for Count</Text></View>
//                                     </View>
//                                     <View style={[styles.viewWithColor,{ width: "49.5%" }]}>
//                                     <View style={{ fontSize: "10px", textAlign: "left"}}>
//                                             <Text style={{marginBottom: '10px'}}>
//                                                 3. Acceleration / Cornering / Braking
//                                             </Text>
//                                             <View
//                                                 style={{
//                                                     marginLeft: "36px",
//                                                     width: "100%",
//                                                     marginBottom: "-9px",
//                                                 }}
//                                             >
//                                                 <Svg viewBox="0 0 700 20" width={"200%"}>
//                                                     <Rect
//                                                         x="0"
//                                                         rx="2"
//                                                         ry="2"
//                                                         width="25"
//                                                         height="15"
//                                                         fill="rgb(54, 115, 53)"
//                                                     />
//                                                     <Text
//                                                         x="30"
//                                                         y="50%"
//                                                         style={{ height: "15", fontSize: "11px" }}
//                                                     >
//                                                         {" "}
//                                                         Low
//                                                     </Text>
//                                                     <Rect
//                                                         x="90"
//                                                         rx="2"
//                                                         ry="2"
//                                                         width="25"
//                                                         height="15"
//                                                         fill="rgb(251, 191, 0)"
//                                                     />
//                                                     <Text
//                                                         x="120"
//                                                         y="50%"
//                                                         style={{
//                                                             height: "15",
//                                                             fontSize: "11px",
//                                                             marginLeft: "5px",
//                                                         }}
//                                                     >
//                                                         {" "}
//                                                         Medium
//                                                     </Text>
//                                                     <Rect
//                                                         x="190"
//                                                         rx="2"
//                                                         ry="2"
//                                                         width="25"
//                                                         height="15"
//                                                         fill="rgb(255, 0, 0)"
//                                                     />
//                                                     <Text
//                                                         x="220"
//                                                         y="50%"
//                                                         style={{ height: "15", fontSize: "11px" }}
//                                                     >
//                                                         {" "}
//                                                         High
//                                                     </Text>
//                                                 </Svg>
//                                             </View>
//                                         </View>

//                                         <View style={{ marginTop: "-15px" }}>
//                                             <Svg>
//                                                 <Line
//                                                     x1="8.5"
//                                                     y1="40"
//                                                     x2="8.5"
//                                                     y2="180"
//                                                     stroke="black"
//                                                     strokeWidth="1"
//                                                 />
//                                                 <Line
//                                                     x1="8"
//                                                     y1="180"
//                                                     x2="267"
//                                                     y2="180"
//                                                     stroke="black"
//                                                     strokeWidth="1"
//                                                 />
//                                                 {lmhData.map((item, index) => (
//                                                     <React.Fragment key={index}>
//                                                         <Rect
//                                                             x={item.x}
//                                                             y={item.y}
//                                                             fill={item.color}
//                                                             width="10"
//                                                             height={item.height}
//                                                         />
//                                                         <Text
//                                                             x={item.x + 5}
//                                                             y={item.y + item.height - 4}
//                                                             fill="black"
//                                                             style={{ fontSize: "10px" }}
//                                                             textAnchor="middle"
//                                                         >
//                                                             {item.label}
//                                                         </Text>
//                                                     </React.Fragment>
//                                                 ))}
//                                             </Svg>
//                                         </View>
//                                         <View
//                                             style={{
//                                                 display: "flex",
//                                                 flexDirection: "row",
//                                                 marginTop: "-52px",
//                                             }}
//                                         >
//                                             <Text style={{ fontSize: "9px", marginLeft: "26px" }}>
//                                                 Accelaration
//                                             </Text>
//                                             <Text style={{ fontSize: "9px", marginLeft: "26px" }}>
//                                                 Cornering
//                                             </Text>
//                                             <Text style={{ fontSize: "9px", marginLeft: "35px" }}>
//                                                 Braking
//                                             </Text>
//                                         </View>
//                                         <View><Text style={{ fontSize: "9px", textAlign: 'center', marginTop: '4px' }}>Note: X-axis for Category, Y-axis for Count</Text></View>
//                                     </View>

//                                 </View>
//                                 {/* <View style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: "center", marginTop: '-20px' }}>
//                                     <View style={{ width: "50%" }}>
//                                         <View style={{ marginTop: "7.5px", textAlign: "center" }}>
//                                             <Text style={{ fontSize: "9px" }}>
//                                                 Note: X-axis for Category, Y-axis for Count
//                                             </Text>
//                                         </View>
//                                     </View>

//                                     <View style={{ width: "50%" }}>
//                                         <View style={{ marginTop: "7.5px", textAlign: "center" }}>
//                                             <Text style={{ fontSize: "9px" }}>
//                                                 Note: X-axis for Category, Y-axis for Count
//                                             </Text>
//                                         </View>
//                                     </View>
//                                 </View> */}
//                             {/* </View> */}

//                             <View style={styles.viewWithColor}>
//                                 <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
//                                     <Text style={styles.heading}>4. Risk Alert (Top {maxRowsRA})</Text>
//                                     <Text style={{ fontSize: "10px" }}>Showing for {UtilDateTime.getCurrentYear()}</Text>
//                                 </View>

//                                 <View>
//                                     <View style={{ flexDirection: "row", height: changeableheight, backgroundColor: "#9e98fb" }}>
//                                         {columns.map((column, index) => (
//                                             <View
//                                                 key={index}
//                                                 style={{
//                                                     flex: column === "date" ? 1.8 : 1,
//                                                     justifyContent: "center",
//                                                     alignItems: "center",
//                                                     fontSize: "9px",
//                                                     border: "1px dashed lightgrey",
//                                                     borderLeft: index === 0 ? "1px dashed lightgrey" : "none",  
//                                                 }}
//                                             >
//                                                 <Text style={fontBold} break>
//                                                     {columnDisplayNameMap[column]}
//                                                 </Text>
//                                             </View>
//                                         ))}
//                                     </View>
//                                     {Array.from({ length: riskAlertPdfPropsrowCount }).map(
//                                         (_, rowIndex) => (
//                                             <View
//                                                 key={rowIndex}
//                                                 style={{
//                                                     flexDirection: "row",
//                                                     height: changeableheight,
//                                                     borderBottom:
//                                                         rowIndex === 9
//                                                             ? "1px dashed lightgrey"
//                                                             : // : "1px dashed black",
//                                                             "1px dashed lightgrey",
//                                                 }}
//                                             >
//                                                 {columns.map((column, colIndex) => {
//                                                     const isFirstColumn = colIndex === 0;
//                                                     const isLastColumn = colIndex === columns.length - 1;

//                                                     const cellData =
//                                                         riskAlertPdf[rowIndex] &&
//                                                             riskAlertPdf[rowIndex][column] !== undefined
//                                                             ? riskAlertPdf[rowIndex][column]
//                                                             : null;

//                                                     const borderStyles = {
//                                                         flex: column === "date" ? 1.8 : 1,
//                                                         justifyContent: "center",
//                                                         alignItems: "center",
//                                                         fontSize: "8px",
//                                                         borderLeft: isFirstColumn
//                                                             ? "1px dashed lightgrey"
//                                                             : // : "1px dashed black",
//                                                             "1px dashed lightgrey",
//                                                         borderRight: isLastColumn
//                                                             ? "1px dashed lightgrey"
//                                                             : "none",
//                                                     };

//                                                     return (
//                                                         <View key={colIndex} style={borderStyles}>
//                                                             {column === "alertImage" && cellData ? (
//                                                                 <Image
//                                                                     src={require(`../../../assets/images/${cellData == "Round-about.svg" ? 'Round-About.png' :  cellData}`)}
//                                                                     style={{ width: "15px", height: "15px" }}
//                                                                 />
//                                                             ) : column === "tripIcon" && cellData ? (
//                                                                 <Image
//                                                                     src={require(`../../../assets/images/TripIcons/${cellData}`)}
//                                                                     style={{ width: "5px", height: "5px" }}
//                                                                 />
//                                                             ) : (
//                                                                 <Text style={{ fontSize: "8px" }} break>
//                                                                     {cellData !== null && cellData !== undefined
//                                                                         ? cellData
//                                                                         : "-"}
//                                                                 </Text>
//                                                             )}
//                                                         </View>
//                                                     );
//                                                 })}
//                                             </View>
//                                         )
//                                     )}
//                                 </View>
//                             </View>

//                         </View>
//                         <PdfFooter pageNumber={1} totalPages={3} pathname={pathname} />
//                     </Page>

//                     <Page totalPages={totalPages++} style={styles.page}>

//                         <PdfHeader />
//                         <View style={{ paddingHorizontal: 30, marginTop: 10, marginBottom: 10 }}>
//                             <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
//                             <View style={styles.viewWithColor}>
//                                 <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
//                                     <Text style={styles.heading}>5. Acceleration / Cornering / Braking (Top {maxRows})</Text>
//                                     <Text style={{ fontSize: "10px" }}>Showing for {UtilDateTime.getCurrentYear()}</Text>
//                                 </View>

//                                 <View>
//                                     <View
//                                         style={{ flexDirection: "row", height: changeableheight }}
//                                     >
//                                         {ACBColumn.map((column, index) => (
//                                             <View
//                                                 key={index}
//                                                 style={{
//                                                     flex: column === "date" ? 1.8 : 1,
//                                                     justifyContent: "center",
//                                                     alignItems: "center",
//                                                     fontSize: "9px",
//                                                     // borderBottom: "1px dashed lightgrey",
//                                                     // borderRight: "1px dashed lightgrey",
//                                                     // borderTop: "1px dashed lightgrey",
//                                                     // borderLeft: index === 0 ? "1px dashed lightgrey" : "none",
//                                                     border: "1px dashed lightgrey",
//                                                     // borderBottom: "1px dashed lightgrey",
//                                                     borderLeft: index === 0 ? "1px dashed lightgrey" : "none",
//                                                     backgroundColor: "#9e98fb"
//                                                 }}
//                                             >
//                                                 <Text style={fontBold} break>
//                                                     {columnDisplayNameMap1[column]}
//                                                 </Text>
//                                             </View>
//                                         ))}
//                                     </View>

//                                     {Array.from({ length: aCBDataPropsrowCount }).map(
//                                         (_, rowIndex) => (
//                                             <View
//                                                 key={rowIndex}
//                                                 style={{
//                                                     flexDirection: "row",
//                                                     height: changeableheight,
//                                                     borderBottom:
//                                                         rowIndex === 9
//                                                             ? "1px dashed lightgrey"
//                                                             : // : "1px dashed black",
//                                                             "1px dashed lightgrey",
//                                                 }}
//                                             >
//                                                 {ACBColumn.map((column, colIndex) => (
//                                                     <View
//                                                         key={colIndex}
//                                                         style={{
//                                                             flex: column === "date" ? 1.8 : 1,
//                                                             justifyContent: "center",
//                                                             alignItems: "center",
//                                                             fontSize: "8px",
//                                                             // borderBottom: "1px dashed lightgrey",
//                                                             // borderRight: "1px dashed lightgrey",
//                                                             // borderLeft:
//                                                             //   colIndex === 0 ? "1px dashed lightgrey" : "none",
//                                                             borderLeft:
//                                                                 colIndex === 0
//                                                                     ? "1px dashed lightgrey"
//                                                                     : // : "1px dashed black",
//                                                                     "1px dashed lightgrey",
//                                                             borderRight:
//                                                                 colIndex === column.length - 1
//                                                                     ? "1px dashed lightgrey"
//                                                                     : "none",
//                                                         }}
//                                                     >
//                                                         <Text style={{ fontSize: "8px" }} break>
//                                                             {aCBDataProps[rowIndex] &&
//                                                                 aCBDataProps[rowIndex][column] !== undefined
//                                                                 ? aCBDataProps[rowIndex][column]
//                                                                     .charAt(0)
//                                                                     .toUpperCase() +
//                                                                 aCBDataProps[rowIndex][column].slice(1)
//                                                                 : // .toLowerCase()
//                                                                 "-"}
//                                                         </Text>
//                                                     </View>
//                                                 ))}
//                                             </View>
//                                         )
//                                     )}
//                                 </View>
//                             </View>

//                             <View style={styles.viewWithColor}>
//                                 <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
//                                     <Text style={styles.heading}>6. Overspeed (Top {maxRows})</Text>
//                                     <Text style={{ fontSize: "10px" }}>Showing for {UtilDateTime.getCurrentYear()}</Text>
//                                 </View>

//                                 <View>
//                                     <View
//                                         style={{ flexDirection: "row", height: changeableheight }}
//                                     >
//                                         {columns1.map((column, index) => (
//                                             <View
//                                                 key={index}
//                                                 style={{
//                                                     flex: column === "date" ? 1.5 : 1,
//                                                     justifyContent: "center",
//                                                     alignItems: "center",
//                                                     fontSize: "9px",
//                                                     // borderBottom: "1px dashed lightgrey",
//                                                     // borderRight: "1px dashed lightgrey",
//                                                     // borderTop: "1px dashed lightgrey",
//                                                     // borderLeft: index === 0 ? "1px dashed lightgrey" : "none",
//                                                     border: "1px dashed lightgrey",
//                                                     // borderBottom: "1px dashed lightgrey",
//                                                     borderLeft: index === 0 ? "1px dashed lightgrey" : "none",
//                                                     backgroundColor: "#9e98fb"
//                                                 }}
//                                             >
//                                                 <Text style={fontBold} break>
//                                                     {columnDisplayNameMap2[column]}
//                                                 </Text>
//                                             </View>
//                                         ))}
//                                     </View>

//                                     {Array.from({ length: osDataPropsrowCount }).map(
//                                         (_, rowIndex) => (
//                                             <View
//                                                 key={rowIndex}
//                                                 style={{
//                                                     flexDirection: "row",
//                                                     height: changeableheight,
//                                                     borderBottom:
//                                                         rowIndex === 5
//                                                             ? "1px dashed lightgrey"
//                                                             : // : "1px dashed black",
//                                                             "1px dashed lightgrey",
//                                                 }}
//                                             >
//                                                 {columns1.map((column, colIndex) => (
//                                                     <View
//                                                         key={colIndex}
//                                                         style={{
//                                                             flex: column === "date" ? 1.5 : 1,
//                                                             justifyContent: "center",
//                                                             alignItems: "center",
//                                                             fontSize: "8px",
//                                                             // borderBottom: "1px dashed lightgrey",
//                                                             // borderRight: "1px dashed lightgrey",
//                                                             // borderLeft:
//                                                             //   colIndex === 0 ? "1px dashed lightgrey" : "none",
//                                                             borderLeft:
//                                                                 colIndex === 0
//                                                                     ? "1px dashed lightgrey"
//                                                                     : // : "1px dashed black",
//                                                                     "1px dashed lightgrey",

//                                                             borderRight:
//                                                                 colIndex === 3 ? "1px dashed lightgrey" : "none",
//                                                         }}
//                                                     >
//                                                         <Text style={{ fontSize: "8px" }} break>
//                                                             {osDataProps[rowIndex] &&
//                                                                 osDataProps[rowIndex][column] !== undefined
//                                                                 ? osDataProps[rowIndex][column]
//                                                                 : "-"}
//                                                         </Text>
//                                                     </View>
//                                                 ))}
//                                             </View>
//                                         )
//                                     )}
//                                 </View>
//                             </View>

//                             <View style={styles.viewWithColor}>
//                                 <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
//                                     <Text style={styles.heading}>7. Mobile Screen Use (Top {maxRows})</Text>
//                                     <Text style={{ fontSize: "10px" }}>Showing for {UtilDateTime.getCurrentYear()}</Text>
//                                 </View>

//                                 <View>
//                                     <View
//                                         style={{ flexDirection: "row", height: changeableheight }}
//                                     >
//                                         {columns2.map((column, index) => (
//                                             <View
//                                                 key={index}
//                                                 style={{
//                                                     flex: column === "date" ? 1.8 : 1,
//                                                     justifyContent: "center",
//                                                     alignItems: "center",
//                                                     fontSize: "9px",
//                                                     borderBottom: "1px dashed lightgrey",
//                                                     // borderRight: "1px dashed lightgrey",
//                                                     // borderTop: "1px dashed lightgrey",
//                                                     // borderLeft: index === 0 ? "1px dashed lightgrey" : "none",
//                                                     border: "1px dashed lightgrey",
//                                                     // borderBottom: "1px dashed lightgrey",
//                                                     borderLeft: index === 0 ? "1px dashed lightgrey" : "none",
//                                                     backgroundColor: "#9e98fb"
//                                                 }}
//                                             >
//                                                 <Text style={fontBold} break>
//                                                     {columnDisplayNameMap3[column]}
//                                                 </Text>
//                                             </View>
//                                         ))}
//                                     </View>

//                                     {Array.from({ length: msuPdfDataPropsrowCount }).map(
//                                         (_, rowIndex) => (
//                                             <View
//                                                 key={rowIndex}
//                                                 style={{
//                                                     flexDirection: "row",
//                                                     height: changeableheight,
//                                                     borderBottom:
//                                                         rowIndex === 5
//                                                             ? "1px dashed lightgrey"
//                                                             : // : "1px dashed black",
//                                                             "1px dashed lightgrey",
//                                                 }}
//                                             >
//                                                 {columns2.map((column, colIndex) => (
//                                                     <View
//                                                         key={colIndex}
//                                                         style={{
//                                                             flex: column === "date" ? 1.8 : 1,
//                                                             justifyContent: "center",
//                                                             alignItems: "center",
//                                                             textAlign: "right",
//                                                             fontSize: "8px",
//                                                             // borderBottom: "1px dashed lightgrey",
//                                                             // borderRight: "1px dashed lightgrey",
//                                                             // borderLeft:
//                                                             //   colIndex === 0 ? "1px dashed lightgrey" : "none",
//                                                             borderLeft:
//                                                                 colIndex === 0
//                                                                     ? "1px dashed lightgrey"
//                                                                     : "1px dashed lightgrey",
//                                                             // : "1px dashed black",
//                                                             borderRight:
//                                                                 colIndex === 2 ? "1px dashed lightgrey" : "none",
//                                                         }}
//                                                     >
//                                                         <Text style={{ fontSize: "8px" }} break>
//                                                             {msuPdfDataProps[rowIndex] &&
//                                                                 msuPdfDataProps[rowIndex][column] !== undefined
//                                                                 ? msuPdfDataProps[rowIndex][column]
//                                                                 : "-"}
//                                                         </Text>
//                                                     </View>
//                                                 ))}
//                                             </View>
//                                         )
//                                     )}
//                                 </View>
//                             </View>

//                         </View>
//                         <PdfFooter pageNumber={2} totalPages={3} pathname={pathname} />
//                     </Page>

//                     <Page totalPages={totalPages++} style={styles.page}>

//                         <PdfHeader />
//                         <View style={{ paddingHorizontal: 30, marginTop: 10, marginBottom: 10 }}>
//                             <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />

//                             <View style={styles.viewWithColor}>
//                                 <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
//                                     <Text style={styles.heading}>8. Mobile Call Use (Top {maxRows})</Text>
//                                     <Text style={{ fontSize: "10px" }}>Showing for {UtilDateTime.getCurrentYear()}</Text>
//                                 </View>

//                                 <View>
//                                     <View
//                                         style={{ flexDirection: "row", height: changeableheight }}
//                                     >
//                                         {columns2.map((column, index) => (
//                                             <View
//                                                 key={index}
//                                                 style={{
//                                                     flex: column === "date" ? 1.8 : 1,
//                                                     justifyContent: "center",
//                                                     alignItems: "center",
//                                                     fontSize: "9px",
//                                                     // borderBottom: "1px dashed lightgrey",
//                                                     // borderRight: "1px dashed lightgrey",
//                                                     // borderTop: "1px dashed lightgrey",
//                                                     // borderLeft: index === 0 ? "1px dashed lightgrey" : "none",
//                                                     border: "1px dashed lightgrey",
//                                                     // borderBottom: "1px dashed lightgrey",
//                                                     borderLeft: index === 0 ? "1px dashed lightgrey" : "none",
//                                                     backgroundColor: "#9e98fb"
//                                                 }}
//                                             >
//                                                 <Text style={fontBold} break>
//                                                     {columnDisplayNameMap3[column]}
//                                                 </Text>
//                                             </View>
//                                         ))}
//                                     </View>
//                                     {Array.from({ length: mcuPdfDataPropsrowCount }).map(
//                                         (_, rowIndex) => (
//                                             <View
//                                                 key={rowIndex}
//                                                 style={{
//                                                     flexDirection: "row",
//                                                     height: changeableheight,
//                                                     borderBottom:
//                                                         rowIndex === 5
//                                                             ? "1px dashed lightgrey"
//                                                             : // : "1px dashed black",
//                                                             "1px dashed lightgrey",
//                                                 }}
//                                             >
//                                                 {columns2.map((column, colIndex) => (
//                                                     <View
//                                                         key={colIndex}
//                                                         style={{
//                                                             flex: column === "date" ? 1.8 : 1,
//                                                             justifyContent: "center",
//                                                             alignItems: "center",
//                                                             fontSize: 8,
//                                                             // borderBottom: "1px dashed lightgrey",
//                                                             // borderRight: "1px dashed lightgrey",
//                                                             // borderLeft:
//                                                             //   colIndex === 0 ? "1px dashed lightgrey" : "none",
//                                                             borderLeft:
//                                                                 colIndex === 0
//                                                                     ? "1px dashed lightgrey"
//                                                                     : "1px dashed lightgrey",
//                                                             // : "1px dashed black",
//                                                             borderRight:
//                                                                 colIndex === 2 ? "1px dashed lightgrey" : "none",
//                                                         }}
//                                                     >
//                                                         <Text style={{ fontSize: 8 }} break>
//                                                             {mcuPdfDataProps[rowIndex] &&
//                                                                 mcuPdfDataProps[rowIndex][column] !== undefined
//                                                                 ? mcuPdfDataProps[rowIndex][column]
//                                                                 : "-"}
//                                                         </Text>
//                                                     </View>
//                                                 ))}
//                                             </View>
//                                         ))}
//                                 </View>
//                             </View>
//                         </View>
//                         <PdfFooter pageNumber={3} totalPages={3} pathname={pathname} />
//                     </Page>
//                 </Document>
//             </PDFViewer>
//         </React.Fragment>
//     )
// }

// export default ColouredriskAlertSummaryPdf;

import { Document, Image, Line, Page, PDFViewer, Rect, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import React from 'react'
import PdfHeader from '../Components/pdfHeaderV1';
import PdfHeading from '../Components/pdfHeading';
import PdfFooter from '../Components/pdfFooterV1';
import { useLocation } from 'react-router-dom';
import UtilDateTime from '../../../util/UtilDateTime';
import ValueFormat from '../../../util/ValueFormat';
import { globalPDF } from '../../../assets/css/commonCssTextForPdf';

const styles = StyleSheet.create({
    pdfViewer: {
        height: "96%",
        width: "100%",
        marginTop: "20px",
        marginBottom: "5px",
    },
    document: {
        marginTop: "15px",
        marginBottom: "5px",
    },
    page: {
        width: "100%",
    },
    viewWithColor: {
        border: '1px solid lightgrey',
        marginTop: 10,
        marginBottom: 0,
        padding: 15,
        paddingTop: 10,
        borderRadius: 10
    },
    heading: {
        fontSize: 11,
        marginBottom: 5
    }
});

const fontBold = {
    fontFamily: "Helvetica-Bold",
};

const ColouredriskAlertSummaryPdf = ({
    riskAlertPdf,
    aCBDataProps,
    rccProps,
    osMcuMsuProps,
    aCBProps,
    osDataProps,
    msuPdfDataProps,
    mcuPdfDataProps,
    section2filter,
}) => {
    // console.log("riskAlertPdf", riskAlertPdf);
    const pathname = useLocation();
    var totalPages = 0;
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    let period = "";
    let startDate = "";
    let endDate = "";

    if (section2filter.durationFilter === "CURRENT_YEAR") {
        period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
        startDate = UtilDateTime.getCurrentYear(section2filter.startDateTime);
        endDate = "";
    } else if (section2filter.durationFilter === "CURRENT_MONTH") {
        period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
        startDate = UtilDateTime.getCurrentMonth(section2filter.startDateTime);
        endDate = "";
    } else if (section2filter.durationFilter === "CURRENT_WEEK") {
        period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
        startDate = UtilDateTime.getCurrentWeek1(section2filter.startDateTime);
        endDate = "";
    } else if (section2filter.durationFilter === "CURRENT_DAY") {
        period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
        startDate = UtilDateTime.getCurrentDay(section2filter.startDateTime);
        endDate = "";
    } else {
        period = ValueFormat.smallLettersFormat(section2filter.durationFilter);
        startDate = section2filter.startDateTime.split(" ")[0];
        endDate = section2filter.endDateTime.split(" ")[0];
    }

    const periodFormat = [period, startDate, endDate];
    var currentDate = new Date();
    const heading = "User Dashboard (Risk Alert Summary)";
    const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

    if (!osMcuMsuProps || osMcuMsuProps.length < 3) {
        // console.error("osMcuMsuProps is undefined or has less than 3 elements");
        return null;
    }

    const overSpeed = osMcuMsuProps[0].toString();
    const mobileCallUse = osMcuMsuProps[1].toString();
    const mobileScreenUse = osMcuMsuProps[2].toString();

    const originalMax = Math.max(
        parseFloat(overSpeed),
        parseFloat(mobileCallUse),
        parseFloat(mobileScreenUse)
    );

    const convertToHeight = (values, newMax) => {
        return values.map((value) => {
            const numericValue = parseFloat(value);
            // console.log("Numeric value:", numericValue);
            if (!isNaN(numericValue)) {
                const originalMin = 0;
                const newMin = 0;
                let mappedValue =
                    ((numericValue - originalMin) / (originalMax - originalMin)) *
                    (newMax - newMin) +
                    newMin;
                mappedValue = Math.min(Math.max(mappedValue, newMin), newMax);
                mappedValue *= -1;
                return mappedValue;
            } else {
                // console.log("Error: Value is not a number");
                return 0;
            }
        });
    };

    const labels = ["Overspeed", "Mobile Call Use", "Mobile Screen Use"];
    const values = [overSpeed, mobileCallUse, mobileScreenUse];
    const heights = convertToHeight(values, 135);

    const data1 = values.map((value, index) => ({
        // x: 26 + index * (100 / values.length) + 56,   //this is for if in future they change it to four values or 1 values
        x: 75 + index * 170,
        y: 177,
        width: "10",
        height: heights[index],
        fill: "rgb(84, 112, 198)",
        text: value,
        label: labels[index],
    }));

    const lmh = {
        low: ValueFormat.parseAsString(aCBProps.low),
        medium: ValueFormat.parseAsString(aCBProps.medium),
        high: ValueFormat.parseAsString(aCBProps.high),
    };

    const maxLMHValue = Math.max(
        ...Object.values(lmh)
            .flat()
            .map((value) => parseInt(value, 10))
    );

    const generateLMHData = () => {
        const lmhData = [];
        const severityOrder = ["low", "medium", "high"];

        for (let setIndex = 0; setIndex < 3; setIndex++) {
            severityOrder.forEach((severity, severityIndex) => {
                const value = lmh[severity][setIndex];
                const x = 65 + setIndex * (17 * 3 + 100) + severityIndex * 17;
                const y = 179.5;
                const label = value;
                const height = -(value / maxLMHValue) * 135;
                let color = "rgb(54, 115, 53)";

                if (severity === "medium") {
                    color = "rgb(251, 191, 0)";
                } else if (severity === "high") {
                    color = "rgb(255, 0, 0)";
                }

                lmhData.push({ x, y, label, height, color });
            });
        }

        return lmhData;
    };

    const lmhData = generateLMHData();

    const columns = ["tripIcon", "alertImage", "rideName", "date", "speed"];
    const columnDisplayNameMap = {
        tripIcon: "Trip Icon",
        alertImage: "Alert Image",
        rideName: "Ride Name",
        date: "Date/Time",
        speed: "Speed (km/hr)",
    };

    const ACBColumn = ["rideName", "date", "type", "subCategoryLevel", "speed"];
    const columnDisplayNameMap1 = {
        rideName: "Ride Name",
        date: "Date/Time",
        type: "Type",
        subCategoryLevel: "Subtype",
        speed: "Speed (km/hr)",
    };

    const columns1 = ["rideName", "date", "speedLimit", "speed"];
    const columnDisplayNameMap2 = {
        rideName: "Ride Name",
        date: "Date/Time",
        speedLimit: "Speed Limit",
        speed: "Speed (km/hr)",
    };
    const columns2 = ["rideName", "date", "speed"];
    const columnDisplayNameMap3 = {
        rideName: "Ride Name",
        date: "Date/Time",
        speed: "Speed (km/hr)",
    };


    const maxRowsRA = 5;
    const maxRows = 10; // Maximum rows to render
    const aCBDataPropsrowCount = Math.min(aCBDataProps?.length, maxRows);
    const riskAlertPdfPropsrowCount = Math.min(riskAlertPdf?.length, maxRowsRA);
    const osDataPropsrowCount = Math.min(osDataProps?.length, maxRows);
    const msuPdfDataPropsrowCount = Math.min(msuPdfDataProps?.length, maxRows);
    const mcuPdfDataPropsrowCount = Math.min(mcuPdfDataProps?.length, maxRows);

    const { data } = rccProps;

    // Define the static data with titles and corresponding values from rccProps.data
    const staticData = [
        { title: "Animal Crossing", value: data[0] },
        { title: "Caution", value: data[1] },
        { title: "Curves", value: data[2] },
        { title: "Hill", value: data[3] },
        { title: "Hill Downwards", value: data[4] },
        { title: "Hill Upwards", value: data[5] },
        { title: "Icy Condition", value: data[6] },
        { title: "Intersection", value: data[7] },
        { title: "Lane Merge", value: data[8] },
        { title: "Low Gear Area", value: data[9] },
        { title: "Narrow Road", value: data[10] },
        { title: "No Overtaking", value: data[11] },
        { title: "Pedestrian Crossing", value: data[12] },
        { title: "Priority", value: data[13] },
        { title: "Railway Crossing", value: data[14] },
        { title: "Risk Grounding", value: data[15] },
        { title: "Round About", value: data[16] },
        { title: "School Zone", value: data[17] },
        { title: "Slippery Road", value: data[18] },
        { title: "Stop Sign", value: data[19] },
        { title: "Traffic Light", value: data[20] },
        { title: "Wind", value: data[21] },
        { title: "Winding Road", value: data[22] },
        { title: "Yield", value: data[23] },
    ];
    const table1 = staticData.slice(0, 8);
    const table2 = staticData.slice(8, 16);
    const table3 = staticData.slice(16);

    const changeableheight = "25px";

    return (
        <React.Fragment>
            <PDFViewer style={styles.pdfViewer}>
                <Document style={styles.document}>

                    <Page size={"A2"} totalPages={totalPages++} style={styles.page}>

                        <PdfHeader />
                        <View style={{ paddingHorizontal: 30, marginTop: 10, marginBottom: 10 }}>
                            <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <View style={[styles.viewWithColor, { width: '49.5%' }]}>
                                    <Text style={styles.heading}>1. Risk Alert Count</Text>

                                    <View style={{ flexDirection: "row", marginBottom: "5px" }}>
                                        <View
                                            style={{
                                                width: "160px",
                                                border: "1px solid lightgrey",
                                                borderBottom: "none",
                                                marginRight: "27.5px",
                                                borderLeft: "none",
                                                borderRight: "none",
                                            }}
                                        >
                                            {table1.map((item, index) => (
                                                <View
                                                    key={index}
                                                    style={{
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        fontSize: "9px",
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            borderLeft: "1px solid lightgrey",
                                                            borderBottom: "1px solid lightgrey",
                                                            borderRight: "1px solid lightgrey",
                                                            width: "100px",
                                                            padding: "5px",
                                                        }}
                                                    >
                                                        <Text>{item.title}</Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            borderBottom: "1px solid lightgrey",
                                                            borderRight: "1px solid lightgrey",
                                                            width: "60px",
                                                            textAlign: "right",
                                                            padding: "5px",
                                                            paddingRight: '25px'
                                                        }}
                                                    >
                                                        <Text>{item.value}</Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>

                                        <View
                                            style={{
                                                width: "160px",
                                                border: "1px solid lightgrey",
                                                borderBottom: "none",
                                                marginRight: "27.5px",
                                                borderLeft: "none",
                                                borderRight: "none",
                                            }}
                                        >
                                            {table2.map((item, index) => (
                                                <View
                                                    key={index}
                                                    style={{
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        fontSize: "9px",
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            borderLeft: "1px solid lightgrey",
                                                            borderBottom: "1px solid lightgrey",
                                                            borderRight: "1px solid lightgrey",
                                                            width: "100px",
                                                            padding: "5px",
                                                        }}
                                                    >
                                                        <Text>{item.title}</Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            borderBottom: "1px solid lightgrey",
                                                            borderRight: "1px solid lightgrey",
                                                            width: "60px",
                                                            textAlign: "right",
                                                            padding: "5px",
                                                            paddingRight: '25px'
                                                        }}
                                                    >
                                                        <Text>{item.value}</Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>

                                        <View
                                            style={{
                                                width: "160px",
                                                border: "1px solid lightgrey",
                                                borderBottom: "none",
                                                borderLeft: "none",
                                                borderRight: "none",
                                            }}
                                        >
                                            {table3.map((item, index) => (
                                                <View
                                                    key={index}
                                                    style={{
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        fontSize: "9px",
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            borderLeft: "1px solid lightgrey",
                                                            borderBottom: "1px solid lightgrey",
                                                            borderRight: "1px solid lightgrey",
                                                            width: "100px",
                                                            padding: "5px",
                                                        }}
                                                    >
                                                        <Text>{item.title}</Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            borderBottom: "1px solid lightgrey",
                                                            borderRight: "1px solid lightgrey",
                                                            width: "60px",
                                                            textAlign: "right",
                                                            padding: "5px",
                                                            paddingRight: '25px'
                                                        }}
                                                    >
                                                        <Text>{item.value}</Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                </View>
                                <View style={[styles.viewWithColor, { height: "240px", width: "49.5%" }]}>
                                    <View style={{ fontSize: "10px", textAlign: "left" }}>
                                        <Text> 2. Overspeed / Mobile Call Use / Mobile Screen Use </Text>

                                    </View>
                                    <Svg width="100%">
                                        <Line
                                            x1="0.5"
                                            y1="20"
                                            x2="0.5"
                                            y2="177.5"
                                            stroke="black"
                                            strokeWidth="1"
                                        />
                                        <Line
                                            x1="0"
                                            y1="177.5"
                                            x2="480"
                                            y2="177.5"
                                            stroke="black"
                                            strokeWidth="1"
                                        />

                                        {data1.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Rect
                                                    x={item.x}
                                                    y={item.y}
                                                    width={item.width}
                                                    height={item.height}
                                                    fill={item.fill}
                                                    textAnchor='middle'
                                                />
                                                <Text
                                                    x={item.x + 5}
                                                    textAnchor="middle"
                                                    y={item.y + item.height - 4}
                                                    fill="black"
                                                    style={{ fontSize: "10px" }}
                                                >
                                                    {item.text}
                                                </Text>
                                            </React.Fragment>
                                        ))}
                                    </Svg>

                                    <View
                                        style={{
                                            flexDirection: "row",
                                            marginLeft: '60px',
                                            gap: '110px',
                                            marginTop: "-154px",
                                        }}
                                    >
                                        {data1.map((item, index) => (
                                            <Text key={index} style={{ fontSize: "9px" }}>
                                                {item.label}
                                            </Text>
                                        ))}
                                    </View>
                                    <View><Text style={{ fontSize: "9px", textAlign: 'center', marginVertical: '10px' }}>Note: X-axis for Category, Y-axis for Count</Text></View>
                                </View>
                            </View>

                            <View
                                style={{
                                    width: "100%",
                                    height: "250.666px",
                                    marginTop: "10px",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >

                                <View style={[styles.viewWithColor, { width: "49.5%" }]}>
                                    <View style={{ fontSize: "10px", textAlign: "left" }}>
                                        <Text style={{ marginBottom: '10px' }}>
                                            3. Acceleration / Cornering / Braking
                                        </Text>
                                        <View
                                            style={{
                                                marginLeft: "36px",
                                                width: "100%",
                                                marginBottom: "-9px",
                                            }}
                                        >
                                            <Svg viewBox="0 0 700 20" width={"200%"}>
                                                <Rect
                                                    x="0"
                                                    rx="2"
                                                    ry="2"
                                                    width="25"
                                                    height="15"
                                                    fill="rgb(54, 115, 53)"
                                                />
                                                <Text
                                                    x="30"
                                                    y="50%"
                                                    style={{ height: "15", fontSize: "11px" }}
                                                >
                                                    {" "}
                                                    Low
                                                </Text>
                                                <Rect
                                                    x="90"
                                                    rx="2"
                                                    ry="2"
                                                    width="25"
                                                    height="15"
                                                    fill="rgb(251, 191, 0)"
                                                />
                                                <Text
                                                    x="120"
                                                    y="50%"
                                                    style={{
                                                        height: "15",
                                                        fontSize: "11px",
                                                        marginLeft: "5px",
                                                    }}
                                                >
                                                    {" "}
                                                    Medium
                                                </Text>
                                                <Rect
                                                    x="190"
                                                    rx="2"
                                                    ry="2"
                                                    width="25"
                                                    height="15"
                                                    fill="rgb(255, 0, 0)"
                                                />
                                                <Text
                                                    x="220"
                                                    y="50%"
                                                    style={{ height: "15", fontSize: "11px" }}
                                                >
                                                    {" "}
                                                    High
                                                </Text>
                                            </Svg>
                                        </View>
                                    </View>

                                    <View style={{ marginTop: "-15px" }}>
                                        <Svg>
                                            <Line
                                                x1="8.5"
                                                y1="40"
                                                x2="8.5"
                                                y2="180"
                                                stroke="black"
                                                strokeWidth="1"
                                            />
                                            <Line
                                                x1="8"
                                                y1="180"
                                                x2="500"
                                                y2="180"
                                                stroke="black"
                                                strokeWidth="1"
                                            />
                                            {lmhData.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <Rect
                                                        x={item.x}
                                                        y={item.y}
                                                        fill={item.color}
                                                        width="10"
                                                        height={item.height}
                                                    />
                                                    <Text
                                                        x={item.x + 5}
                                                        y={item.y + item.height - 4}
                                                        fill="black"
                                                        style={{ fontSize: "10px" }}
                                                        textAnchor="middle"
                                                    >
                                                        {item.label}
                                                    </Text>
                                                </React.Fragment>
                                            ))}
                                        </Svg>
                                    </View>
                                    <View
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "-52px",
                                        }}
                                    >
                                        <Text style={{ fontSize: "9px", marginLeft: "60px" }}>
                                            Accelaration
                                        </Text>
                                        <Text style={{ fontSize: "9px", marginLeft: "107px" }}>
                                            Cornering
                                        </Text>
                                        <Text style={{ fontSize: "9px", marginLeft: "114px" }}>
                                            Braking
                                        </Text>
                                    </View>
                                    <View><Text style={{ fontSize: "9px", textAlign: 'center', marginVertical: '10px' }}>Note: X-axis for Category, Y-axis for Count</Text></View>
                                </View>

                                <View style={[styles.viewWithColor, { width: "49.5%" }]}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text style={styles.heading}>4. Risk Alert (Top {maxRowsRA})</Text>
                                        <Text style={{ fontSize: "10px", marginBottom: '20px' }}>Showing for {UtilDateTime.getCurrentYear()}</Text>
                                    </View>

                                    <View>
                                        <View style={{ flexDirection: "row", height: changeableheight, backgroundColor: globalPDF.GREY_COLOR }}>
                                            {columns.map((column, index) => (
                                                <View
                                                    key={index}
                                                    style={{
                                                        flex: column === "date" ? 1.8 : 1,
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "9px",
                                                        border: "1px solid lightgrey",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                    }}
                                                >
                                                    <Text style={fontBold} break>
                                                        {columnDisplayNameMap[column]}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                        {Array.from({ length: riskAlertPdfPropsrowCount }).map(
                                            (_, rowIndex) => (
                                                <View
                                                    key={rowIndex}
                                                    style={{
                                                        flexDirection: "row",
                                                        height: changeableheight,
                                                        borderBottom:
                                                            rowIndex === 9
                                                                ? "1px solid lightgrey" :
                                                                "1px solid lightgrey",
                                                    }}
                                                >
                                                    {columns.map((column, colIndex) => {
                                                        const isFirstColumn = colIndex === 0;
                                                        const isLastColumn = colIndex === columns.length - 1;

                                                        const cellData =
                                                            riskAlertPdf[rowIndex] &&
                                                                riskAlertPdf[rowIndex][column] !== undefined
                                                                ? riskAlertPdf[rowIndex][column]
                                                                : null;

                                                        const borderStyles = {
                                                            flex: column === "date" ? 1.8 : 1,
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "8px",
                                                            borderLeft: isFirstColumn
                                                                ? "1px solid lightgrey" :
                                                                "1px solid lightgrey",
                                                            borderRight: isLastColumn
                                                                ? "1px solid lightgrey"
                                                                : "none",
                                                        };

                                                        return (
                                                            <View key={colIndex} style={borderStyles}>
                                                                {column === "alertImage" && cellData ? (
                                                                    <Image
                                                                        src={require(`../../../assets/images/${cellData == "Round-about.svg" ? 'Round-About.png' : cellData}`)}
                                                                        style={{ width: "15px", height: "15px" }}
                                                                    />
                                                                ) : column === "tripIcon" && cellData ? (
                                                                    <Image
                                                                        src={require(`../../../assets/images/TripIcons/${cellData}`)}
                                                                        style={{ width: "5px", height: "5px" }}
                                                                    />
                                                                ) : (
                                                                    <Text style={{ fontSize: "8px" }} break>
                                                                        {cellData !== null && cellData !== undefined
                                                                            ? cellData
                                                                            : "-"}
                                                                    </Text>
                                                                )}
                                                            </View>
                                                        );
                                                    })}
                                                </View>
                                            )
                                        )}
                                    </View>
                                </View>
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <View style={[styles.viewWithColor, { width: '49.5%' }]}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text style={styles.heading}>5. Acceleration / Cornering / Braking (Top {maxRows})</Text>
                                        <Text style={{ fontSize: "10px" }}>Showing for {UtilDateTime.getCurrentYear()}</Text>
                                    </View>

                                    <View>
                                        <View
                                            style={{ flexDirection: "row", height: changeableheight }}
                                        >
                                            {ACBColumn.map((column, index) => (
                                                <View
                                                    key={index}
                                                    style={{
                                                        flex: column === "date" ? 1.8 : 1,
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "9px",
                                                        border: "1px solid lightgrey",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        backgroundColor: globalPDF.GREY_COLOR
                                                    }}
                                                >
                                                    <Text style={fontBold} break>
                                                        {columnDisplayNameMap1[column]}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>

                                        {Array.from({ length: aCBDataPropsrowCount }).map(
                                            (_, rowIndex) => (
                                                <View
                                                    key={rowIndex}
                                                    style={{
                                                        flexDirection: "row",
                                                        height: changeableheight,
                                                        borderBottom:
                                                            rowIndex === 9
                                                                ? "1px solid lightgrey" :
                                                                "1px solid lightgrey",
                                                    }}
                                                >
                                                    {ACBColumn.map((column, colIndex) => (
                                                        <View
                                                            key={colIndex}
                                                            style={{
                                                                flex: column === "date" ? 1.8 : 1,
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                fontSize: "8px",
                                                                borderLeft:
                                                                    colIndex === 0
                                                                        ? "1px solid lightgrey" :
                                                                        "1px solid lightgrey",
                                                                borderRight:
                                                                    colIndex === column.length - 1
                                                                        ? "1px solid lightgrey"
                                                                        : "none",
                                                            }}
                                                        >
                                                            <Text style={{ fontSize: "8px" }} break>
                                                                {aCBDataProps[rowIndex] &&
                                                                    aCBDataProps[rowIndex][column] !== undefined
                                                                    ? aCBDataProps[rowIndex][column]
                                                                        .charAt(0)
                                                                        .toUpperCase() +
                                                                    aCBDataProps[rowIndex][column].slice(1)
                                                                    :
                                                                    "-"}
                                                            </Text>
                                                        </View>
                                                    ))}
                                                </View>
                                            )
                                        )}
                                    </View>
                                </View>

                                <View style={[styles.viewWithColor, { width: '49.5%' }]}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text style={styles.heading}>6. Overspeed (Top {maxRows})</Text>
                                        <Text style={{ fontSize: "10px" }}>Showing for {UtilDateTime.getCurrentYear()}</Text>
                                    </View>

                                    <View>
                                        <View
                                            style={{ flexDirection: "row", height: changeableheight }}
                                        >
                                            {columns1.map((column, index) => (
                                                <View
                                                    key={index}
                                                    style={{
                                                        flex: column === "date" ? 1.5 : 1,
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "9px",
                                                        border: "1px solid lightgrey",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        backgroundColor: globalPDF.GREY_COLOR
                                                    }}
                                                >
                                                    <Text style={fontBold} break>
                                                        {columnDisplayNameMap2[column]}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>

                                        {Array.from({ length: osDataPropsrowCount }).map(
                                            (_, rowIndex) => (
                                                <View
                                                    key={rowIndex}
                                                    style={{
                                                        flexDirection: "row",
                                                        height: changeableheight,
                                                        borderBottom:
                                                            rowIndex === 5
                                                                ? "1px solid lightgrey" :
                                                                "1px solid lightgrey",
                                                    }}
                                                >
                                                    {columns1.map((column, colIndex) => (
                                                        <View
                                                            key={colIndex}
                                                            style={{
                                                                flex: column === "date" ? 1.5 : 1,
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                fontSize: "8px",
                                                                borderLeft:
                                                                    colIndex === 0
                                                                        ? "1px solid lightgrey" :
                                                                        "1px solid lightgrey",

                                                                borderRight:
                                                                    colIndex === 3 ? "1px solid lightgrey" : "none",
                                                            }}
                                                        >
                                                            <Text style={{ fontSize: "8px" }} break>
                                                                {osDataProps[rowIndex] &&
                                                                    osDataProps[rowIndex][column] !== undefined
                                                                    ? osDataProps[rowIndex][column]
                                                                    : "-"}
                                                            </Text>
                                                        </View>
                                                    ))}
                                                </View>
                                            )
                                        )}
                                    </View>
                                </View>
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                                <View style={[styles.viewWithColor, { width: '49.5%' }]}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text style={styles.heading}>7. Mobile Screen Use (Top {maxRows})</Text>
                                        <Text style={{ fontSize: "10px" }}>Showing for {UtilDateTime.getCurrentYear()}</Text>
                                    </View>

                                    <View>
                                        <View
                                            style={{ flexDirection: "row", height: changeableheight }}
                                        >
                                            {columns2.map((column, index) => (
                                                <View
                                                    key={index}
                                                    style={{
                                                        flex: column === "date" ? 1.8 : 1,
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "9px",
                                                        borderBottom: "1px solid lightgrey",
                                                        border: "1px solid lightgrey",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        backgroundColor: globalPDF.GREY_COLOR
                                                    }}
                                                >
                                                    <Text style={fontBold} break>
                                                        {columnDisplayNameMap3[column]}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>

                                        {Array.from({ length: msuPdfDataPropsrowCount }).map(
                                            (_, rowIndex) => (
                                                <View
                                                    key={rowIndex}
                                                    style={{
                                                        flexDirection: "row",
                                                        height: changeableheight,
                                                        borderBottom:
                                                            rowIndex === 5
                                                                ? "1px solid lightgrey" :
                                                                "1px solid lightgrey",
                                                    }}
                                                >
                                                    {columns2.map((column, colIndex) => (
                                                        <View
                                                            key={colIndex}
                                                            style={{
                                                                flex: column === "date" ? 1.8 : 1,
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                textAlign: "right",
                                                                fontSize: "8px",
                                                                borderLeft:
                                                                    colIndex === 0
                                                                        ? "1px solid lightgrey"
                                                                        : "1px solid lightgrey",
                                                                borderRight:
                                                                    colIndex === 2 ? "1px solid lightgrey" : "none",
                                                            }}
                                                        >
                                                            <Text style={{ fontSize: "8px" }} break>
                                                                {msuPdfDataProps[rowIndex] &&
                                                                    msuPdfDataProps[rowIndex][column] !== undefined
                                                                    ? msuPdfDataProps[rowIndex][column]
                                                                    : "-"}
                                                            </Text>
                                                        </View>
                                                    ))}
                                                </View>
                                            )
                                        )}
                                    </View>
                                </View>

                                <View style={[styles.viewWithColor, { width: '49.5%' }]}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text style={styles.heading}>8. Mobile Call Use (Top {maxRows})</Text>
                                        <Text style={{ fontSize: "10px" }}>Showing for {UtilDateTime.getCurrentYear()}</Text>
                                    </View>

                                    <View>
                                        <View
                                            style={{ flexDirection: "row", height: changeableheight }}
                                        >
                                            {columns2.map((column, index) => (
                                                <View
                                                    key={index}
                                                    style={{
                                                        flex: column === "date" ? 1.8 : 1,
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "9px",
                                                        border: "1px solid lightgrey",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        backgroundColor: globalPDF.GREY_COLOR
                                                    }}
                                                >
                                                    <Text style={fontBold} break>
                                                        {columnDisplayNameMap3[column]}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                        {Array.from({ length: mcuPdfDataPropsrowCount }).map(
                                            (_, rowIndex) => (
                                                <View
                                                    key={rowIndex}
                                                    style={{
                                                        flexDirection: "row",
                                                        height: changeableheight,
                                                        borderBottom:
                                                            rowIndex === 5
                                                                ? "1px solid lightgrey" :
                                                                "1px solid lightgrey",
                                                    }}
                                                >
                                                    {columns2.map((column, colIndex) => (
                                                        <View
                                                            key={colIndex}
                                                            style={{
                                                                flex: column === "date" ? 1.8 : 1,
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                fontSize: 8,
                                                                borderLeft:
                                                                    colIndex === 0
                                                                        ? "1px solid lightgrey"
                                                                        : "1px solid lightgrey",
                                                                borderRight:
                                                                    colIndex === 2 ? "1px solid lightgrey" : "none",
                                                            }}
                                                        >
                                                            <Text style={{ fontSize: 8 }} break>
                                                                {mcuPdfDataProps[rowIndex] &&
                                                                    mcuPdfDataProps[rowIndex][column] !== undefined
                                                                    ? mcuPdfDataProps[rowIndex][column]
                                                                    : "-"}
                                                            </Text>
                                                        </View>
                                                    ))}
                                                </View>
                                            ))}
                                    </View>
                                </View>

                            </View>


                        </View>
                        <PdfFooter pageNumber={1} totalPages={1} pathname={pathname} />
                    </Page>

                </Document>
            </PDFViewer>
        </React.Fragment>
    )
}

export default ColouredriskAlertSummaryPdf;
