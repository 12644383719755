import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link, useLocation } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";

// Import menuDropdown


import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";


// import images
import RuduLogo from "../../assets/images/logo/motivAiLogo.svg";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useDispatch } from "react-redux";
import {
  SC_DIVISION_NAME,
  SC_USER_LOGIN_TYPE,
  SC_USER_FIRST_NAME,
  SC_COMPANY_NAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_USERNAME,
  SC_DIVISION_ID,
} from "../../constant/StorageConstant";

import TopBarTimeZoneDetails from "../CommonForBoth/TopbarDropdown/TopBarTimeZoneDetails";
import MyUsersNewTable from "../../pages/Admin/NewTab/tableDataMyuser";

const Header = (props) => {
  const dispatch = useDispatch();
  const { onChangeLayoutMode } = props;
  const [isSearch, setSearch] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [loginType, setLoginType] = useState("");
  const location = useLocation();
  const { pathname } = useLocation();
  const companyName = localStorage.getItem(SC_COMPANY_NAME) || "";
  const userName = localStorage.getItem(SC_USER_FIRST_NAME) || "";
  const userFirstName = localStorage.getItem(SC_LOGIN_USER_FIRST_NAME) || "";
  const userUsername = localStorage.getItem(SC_USER_USERNAME || "");
  const divisionId = localStorage.getItem(SC_DIVISION_ID || "");

  let dashboardLink;

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  if (loginType === "USER") {
    dashboardLink = "/dashboard";
  } else if (loginType === "ADMIN") {
    dashboardLink = "/adminDashboard";
  } else if (loginType === "SUPER_ADMIN") {
    dashboardLink = "/superAdminClientDashboard";
  }

  const generateText = (companyName, userFirstName, userUsername, pathname) => {
    let text = `Welcome, ${companyName.toUpperCase()}`;
    let additionalText = "";
  
    if (pathname.includes('/user-dashboard')) {
      additionalText = `. You are viewing ${userFirstName} ${userUsername && userUsername.length > 0 ? `(${userUsername})` : ""}'s dashboard.`;
    } else if (pathname.includes('/myRides')) {
      additionalText = `. You are viewing ${userFirstName} ${userUsername && userUsername.length > 0 ? `(${userUsername})` : ""}'s ride list.`;
    } else if (pathname.includes('/ridedetails')) {
      additionalText = `. You are viewing ${userFirstName} ${userUsername && userUsername.length > 0 ? `(${userUsername})` : ""}'s ride details.`;
    }
  
    return text + additionalText;
  };
  
  const text = generateText(companyName, userFirstName, userUsername, location.pathname);
  const isLongText = text.length > 95;
  
  const dynamicFontSize = isLongText ? '12px' : '12px';


  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header" style={{ padding: "0px" }}>
          <div className="d-flex">
            <div className="navbar-brand-box" style={{marginLeft:"7px"}}>
              <Link to={dashboardLink} className="logo logo-dark ms-2">
                <span className="logo-sm">
                  <img src={RuduLogo} alt="" height="40px" />
                </span>
                <span className="logo-lg">
                  <img src={RuduLogo} alt="" height="40px" />
                </span>
              </Link>

              <Link to={dashboardLink} className="logo logo-light">
                <span className="logo-sm">
                  <img src={RuduLogo} alt="" height="40px" />
                </span>
                <span className="logo-lg">
                  <img src={RuduLogo} alt="" height="40px" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input type="text" className="form-control"
                  placeholder="Search..." />
                <button className="btn btn-primary" type="button"><i
                  className="bx bx-search-alt align-middle"></i></button>
              </div>
            </form> */}
            {/* <div className="d-none d-md-flex justify-content-center align-items-center" style={{maxWidth: '765px'}}>
    <h5 className="mb-0 mt-1">Drive Safe, Arrive Safe</h5>
</div> */}
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              {/* <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button> */}
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.t("Search") + "..."}
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* {(companyName !== undefined && companyName.length > 0  ) ? ( */}
            {loginType === "ADMIN" && (
              <div className="d-none d-lg-flex align-items-center pe-3 border-right">
                <span style={{ fontSize: dynamicFontSize, fontWeight: 600 }}>
                  {/* Welcome,{" "} */}
                  {/* <span style={{ fontSize: dynamicFontSize, fontWeight: 600 }} className="text-primary">
                    {companyName.toUpperCase()}
                  </span> */}
                  {location.pathname.includes('/user-dashboard') && (
                    <>
                    <span style={{float:"inline-start"}}>You are viewing</span>&nbsp;&nbsp;
                    <span>
                      {/* &nbsp;You are viewing{" "} */}
                      <span style={{ fontSize: dynamicFontSize, fontWeight: 800 }}>
                        {userFirstName}{" "}
                        {userUsername && userUsername.length > 0 ? `(${userUsername})` : ""}
                      </span>
                      's dashboard
                    </span>
                    </>
                  )}
                  {location.pathname.includes("/myRides") && (
                    <>
                    <span style={{float:"inline-end"}}>You are viewing</span><br/>
                    <span>
                      {/* &nbsp;You are viewing{" "} */}
                      <span style={{ fontSize: dynamicFontSize, fontWeight: 800 }}>
                        {userFirstName}{" "}
                        {userUsername && userUsername.length > 0 ? `(${userUsername})` : ""}
                      </span>
                      's ride list
                    </span>
                    </>
                  )}
                  {location.pathname.includes("/ridedetails") && (
                    <>
                    <span style={{float:"inline-end"}}>You are viewing</span><br/>
                    <span>
                      {/* &nbsp;You are viewing{" "} */}
                      <span style={{ fontSize: dynamicFontSize, fontWeight: 800 }}>
                        {userFirstName}{" "}
                        {userUsername && userUsername.length > 0 ? `(${userUsername})` : ""}
                      </span>
                      's ride details
                    </span>
                    </>
                  )}
                </span>
              </div>
            )}

            {loginType === "SUPER_ADMIN" && (
              <div className="d-none d-lg-flex align-items-center pe-3 border-right">
                <span style={{ fontSize: "14px", fontWeight: 600 }}>
                  Welcome,{" "}
                  <span
                    style={{ fontSize: "14px", fontWeight: 600 }}
                    className="text-primary"
                  >
                    {companyName.toUpperCase()}
                  </span>
                </span>
              </div>
            )}
            {loginType === "USER" && (
              <div className="d-none d-lg-flex align-items-center pe-3">
                <span style={{ fontSize: "14px", fontWeight: 600 }}>
                  Welcome,{" "}
                  <span
                    style={{ fontSize: "14px", fontWeight: 600 }}
                    className="text-primary"
                  >
                    {userName.toUpperCase()}
                  </span>
                </span>
              </div>
            )}

           
           

            {/* light / dark mode */}
           

            {/* <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp)
              }}
            >
              {/* <DropdownToggle
                className="btn header-item noti-icon "
                caret
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
            <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu> 
            </Dropdown> */}
            
            <TopBarTimeZoneDetails />
            {/* 
            <div className="dropdown d-inline-block">
              <button
                onClick={() => {
                  dispatch(showRightSidebarAction(!props.showRightSidebar));
                }}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <FeatherIcon
                  icon="settings"
                  className="icon-lg"
                />

              </button>
            </div> */}
             {loginType === "ADMIN" && (
            <div className="d-none d-lg-flex align-items-center pe-3 border-start px-2 py-1 ">
            <Link to="/mySubscription" >Your Work Orders</Link> 
            </div>)}
            {loginType === "ADMIN" && (
            <div className="d-none d-lg-flex align-items-center  border-start px-2 py-1 ">
                    <MyUsersNewTable/>
                    

                    </div>)}

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
