import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from "reactstrap";
import {
  SC_LOGIN_USER_ID,
  SC_USER_FIRST_NAME,
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import FCMNotificationController from "../../controller/FireBasePushNotification/FCMUserController";
import ValueFormat from "../../util/ValueFormat";
import NotificationUserdataList from "./notificationData";
import Loader from "../../components/Common/loader";
import { displayText } from "../../constant/MessageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import Report from "../../controller/Report/Report";

const NotificationPage = () => {
  const [userName, setUserName] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [count, setCount] = useState(0);
  const [notificationData, setNotificationData] = useState(
    NotificationUserdataList
  );
  const [dataLength, setDataLength] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState();

  const [subFilterTypee, setSubFilterTypee] = useState("HIGH_RISK_ALERT");
  const [deviceFilter, setDeviceFilter] = useState("");
  const [nextShow, setNextShow] = useState();
  const [prevShow, setPrevShow] = useState();
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [apiDataUser, setApiDataUser] = useState([]);
  const [userId, setUserId] = useState("");
  const LSuserType = localStorage.getItem(SC_USER_LOGIN_TYPE);
  const LSuserId = localStorage.getItem(SC_LOGIN_USER_ID);

  let colSize1 = 3;
  let colSize2 = 3;
  let colSize3 = 4;

  if (LSuserType == "ADMIN" || LSuserType == "SUPER_ADMIN") {
    colSize1 = 3;
    colSize2 = 3;
    colSize3 = 4;
  } else {
    colSize1 = 4;
    colSize2 = 4;
    colSize3 = 4;
  }

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
    setSearchQuery("");
  };

  //   const [menu, setMenu] = useState(false);
  const pageSize = 10;

  const previous = () => {
    setIndexStart((prev) => prev - pageSize);
    setPageNumber((current) => current - 1);
  };
  const next = () => {
    setIndexStart((prev) => prev + pageSize);
    setPageNumber((current) => current + 1);
  };

  const handleSelect = (userIdTemp, firstName) => {
    setSelectedOption(firstName);
    setUserId(userIdTemp);
  };

  const filterUsers = (user) => {
    return user.firstName.toLowerCase().includes(searchQuery.toLowerCase());
  };

  useEffect(() => {
    var userFirstNameTemp = localStorage.getItem(SC_USER_FIRST_NAME);
    if (userFirstNameTemp.length > 0) {
      try {
        var userFirstName =
          userFirstNameTemp[0].toLocaleUpperCase() + userFirstNameTemp.slice(1);
        setUserName(userFirstName);
      } catch (errMess) {
        // console.log("Error : " + errMess);
      }
    }
  }, []);

  const getUserList = async () => {
    setShowLoader(true);
    var result = await Report.getUserListReport();
    var userData = [];
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData1", resultJSONData);
        if (resultJSONData.length > 0) {
          var firstName = "",
            userIdTemp = "";
          for (let i = 0; i < resultJSONData.length; i++) {
            var userListData = resultJSONData[i];
            firstName = userListData.firstName;
            userIdTemp = userListData.id;

            var userDataListTemp = {
              firstName: ValueFormat.parseAsString(
                ValueFormat.capitalizeAllLetter(userListData.firstName)
              ),
              userIdTemp: userIdTemp,
            };

            userData.push(userDataListTemp);
            setApiDataUser(userData);
          }
        }
      } else {
        // var resultJSONCode = resultJSON.error.message;
        // console.log("Getting user list else error",resultJSONCode);
        // console.log("Status failed");
      }
    } catch (errMes) {
      // console.log("Catch error message: ", errMes);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  const getFCMUserList = async (index) => {
    let uid = "";
    if (LSuserType == "ADMIN" || LSuserType == "SUPER_ADMIN") {
      uid = userId;
    } else if (LSuserType == "USER") {
      uid = LSuserId;
    }
    setShowLoader(true);
    setDataLength(null);
    var result = await FCMNotificationController.FCMNotificationRiskAlertUser(
      index,
      filter,
      deviceFilter,
      startDateTime,
      endDateTime,
      subFilterTypee,
      uid,
      pageSize
    );

    try {
      var resultJSON = JSON.parse(result);
      var resultJSONData = resultJSON.data;
      var status = resultJSON.status;
      var userList = [];
      // console.log("getFCMUserList :" + JSON.stringify(resultJSONData));
      if (status === "SUCCESS") {
        setDataLength(resultJSONData.length);
        for (let i = 0; i < resultJSONData.length; i++) {
          var userStatus = resultJSONData[i];
          var firstName =
            userStatus.firstName.length > 0
              ? ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(userStatus.firstName))
              : "-";
          var username =
            userStatus.username.length > 0
              ? userStatus.username
              : "-";
          var rideName =
            userStatus.deviceName.length > 0
              ? ValueFormat.capitalizeFirstLetterAndFormat(
                userStatus.deviceName
              )
              : "-";
          var rideStatus =
            userStatus.deviceStatus.length > 0
              ? ValueFormat.capitalizeFirstLetter(userStatus.deviceStatus)
              : "-";
          var riskName =
            userStatus.subCategory.length > 0
              ? ValueFormat.capitalizeFirstLetterAndFormat(
                userStatus.subCategory
              )
              : "-";
          var riskValue =
            userStatus.riskAlertValue > 0
              ? Math.round(userStatus.riskAlertValue)
              : 0;
          var riskTime =
            userStatus.deviceDataCreatedAtToTimeZone.length > 0
              ? userStatus.deviceDataCreatedAtToTimeZone
              : 0;
          var rideStartTime =
            userStatus.deviceStartDateTimeToTimeZone.length > 0
              ? userStatus.deviceStartDateTimeToTimeZone
              : 0;
          var riskAlertLevel =
            userStatus.riskAlertLevel.length > 0
              ? ValueFormat.capitalizeFirstLetterAndFormat(
                userStatus.riskAlertLevel
              )
              : "-";

        let deviceTypeTemp ="";
        if (userStatus.origin === "ANDROID") {
          deviceTypeTemp = "Android"
        } else if (userStatus.origin === "ANDROID_SDK"){
          deviceTypeTemp = "Android SDK"
        } else if (userStatus.origin === "IOS") {
          deviceTypeTemp = "iOS"
        } else if (userStatus.origin === "IOS_SDK"){
          deviceTypeTemp = "iOS SDK"
        } else  {
          deviceTypeTemp = "N/A"
        }
          // var deviceType = ValueFormat.parseAsString(
          //   ValueFormat.capitalizeFirstLetter(userStatus.origin)
          // );

          var totalCounts = ValueFormat.parseAsInt(userStatus.totalCount);
          setTotalPageCount(Math.ceil(totalCounts / pageSize));
          setNextShow((totalCounts - index) > pageSize);

          var userDetails = {
            firstName: firstName,
            username: username,
            rideName: rideName,
            rideStatus: rideStatus,
            riskName: riskName,
            riskValue: riskValue,
            riskTime: riskTime,
            rideStartTime: rideStartTime,
            riskAlertLevel: riskAlertLevel,
            deviceType: deviceTypeTemp,
          };
          userList.push(userDetails);
        }
      } else {
        setDataLength(0);
        // console.log("Status failed for fetching notifications");
      }
      setNotificationData(userList);
    } catch (errMess) {
      // console.log("Notification catch error: ", errMess);
    } finally {
      setShowLoader(false);
    }

    if (index == 0) {
      setPrevShow(false);
    } else if (index > 1) {
      setPrevShow(true);
    }
  };

  useEffect(() => {
    setPageNumber(1);
    if (indexStart == 0) setCount((prev) => prev + 1);
    else setIndexStart(0);
  }, [ filter, deviceFilter, startDateTime, endDateTime, subFilterTypee, userId ]);

  useEffect(() => {
    if (count > 0) getFCMUserList(indexStart);
  }, [indexStart, count]);

  return (
    <React.Fragment>
      <div className="page-content">
        {showLoader && <Loader />}
        <Container fluid>
          <Row className="my-2 py-2">
            <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
              <h5 className="mb-0">Notifications</h5>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader style={{ padding: "0px" }}>
                  <Row>
                    <Col
                      md={colSize1}
                      style={{
                        borderRight: "1px solid #DFDEDE",
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "10px",
                          borderBottom: "1px dashed #DFDEDE",
                        }}
                      >
                        <h4
                          className="card-title mb-1 mt-1"
                          style={{ textAlign: "center" }}
                        >
                          Alert Selection
                        </h4>
                      </div>
                      <div
                        className="mt-3 mb-3"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className={
                            subFilterTypee == ""
                              ? "btn btnColorStylesNew btn-sm m-1"
                              : "btn btn-soft-secondary btn-sm m-1"
                          }
                          onClick={() => setSubFilterTypee("")}
                          data-tooltip-id="alle"
                        >
                          All
                          <ReactTooltip
                            id="alle"
                            place="top"
                            content="All"
                            style={{ background: "black" }}
                          />
                        </button>
                        <button
                          type="button"
                          className={
                            subFilterTypee == "HIGH_RISK_ALERT"
                              ? "btn btnColorStylesNew btn-sm m-1"
                              : "btn btn-soft-secondary btn-sm m-1"
                          }
                          onClick={() => setSubFilterTypee("HIGH_RISK_ALERT")}
                          data-tooltip-id="redAlert"
                        >
                          Red Alert
                          <ReactTooltip
                            id="redAlert"
                            place="top"
                            content="Risk alert value is between 90 - 100"
                            style={{ background: "black" }}
                          />
                        </button>
                        <button
                          type="button"
                          className={
                            subFilterTypee == "MEDIUM_RISK_ALERT"
                              ? "btn btnColorStylesNew btn-sm m-1"
                              : "btn btn-soft-secondary btn-sm m-1"
                          }
                          onClick={() => setSubFilterTypee("MEDIUM_RISK_ALERT")}
                          data-tooltip-id="oranAlert"
                        >
                          Amber Alert
                          <ReactTooltip
                            id="oranAlert"
                            place="top"
                            content="Risk alert value is between 60 - 90"
                            style={{ background: "black" }}
                          />
                        </button>
                      </div>
                    </Col>

                    <Col
                      md={colSize2}
                      style={{
                        borderRight: "1px solid #DFDEDE",
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                        <h4
                          className="card-title mb-1 mt-1"
                          style={{ textAlign: "center" }}
                        >
                          Device Selection
                        </h4>
                      </div>
                      <div
                        className="mt-3 mb-3"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className={
                            deviceFilter == ""
                              ? "btn btnColorStylesNew btn-sm me-1"
                              : "btn btn-soft-secondary btn-sm me-1"
                          }
                          onClick={() => setDeviceFilter("")}
                          data-tooltip-id="allBl"
                        >
                          All
                          <ReactTooltip
                            id="allBl"
                            place="top"
                            content="All"
                            style={{ background: "black" }}
                          />
                        </button>
                        <button
                          type="button"
                          className={
                            deviceFilter == "ANDROID"
                              ? "btn btnColorStylesNew btn-sm m-1"
                              : "btn btn-soft-secondary btn-sm m-1"
                          }
                          onClick={() => setDeviceFilter("ANDROID")}
                          data-tooltip-id="androidBl"
                        >
                          Android
                          <ReactTooltip
                            id="androidBl"
                            place="top"
                            content="Android"
                            style={{ background: "black" }}
                          />
                        </button>
                        <button
                          type="button"
                          className={
                            deviceFilter == "IOS"
                              ? "btn btnColorStylesNew btn-sm m-1"
                              : "btn btn-soft-secondary btn-sm m-1"
                          }
                          onClick={() => setDeviceFilter("IOS")}
                          data-tooltip-id="iosBl"
                        >
                          iOS
                          <ReactTooltip
                            id="iosBl"
                            place="top"
                            content="iOS"
                            style={{ background: "black" }}
                          />
                        </button>
                      </div>
                    </Col>

                    <Col
                      md={colSize3}
                      style={{
                        borderRight: "1px solid #DFDEDE",
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                        <h4
                          className="card-title mb-1 mt-1"
                          style={{ textAlign: "center" }}
                        >
                          Duration Selection
                        </h4>
                      </div>
                      <div
                        className="mt-3 mb-3"
                        style={{ textAlign: "center" }}
                      >
                        {/* <button
                        type="button"
                        className={
                          filter == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button> */}
                        <button
                          type="button"
                          className={
                            (filter == "CURRENT_DAY" && !modalIsOpen)
                              ? "btn btnColorStylesNewtn-sm m-1"
                              : "btn btn-soft-secondary btn-sm m-1"
                          }
                          onClick={() => setFilter("CURRENT_DAY")}
                          data-tooltip-id="cd"
                        >
                          CD
                          <ReactTooltip
                            id="cd"
                            place="top"
                            content="Current Day"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            (filter == "CURRENT_WEEK" && !modalIsOpen)
                              ? "btn btnColorStylesNew btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_WEEK")}
                          data-tooltip-id="cw"
                        >
                          CW
                          <ReactTooltip
                            id="cw"
                            place="top"
                            content="Current Week"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            (filter == "CURRENT_MONTH" && !modalIsOpen)
                              ? "btn btnColorStylesNew btn-sm m-1"
                              : "btn btn-soft-secondary btn-sm m-1"
                          }
                          onClick={() => setFilter("CURRENT_MONTH")}
                          data-tooltip-id="cm"
                        >
                          CM
                          <ReactTooltip
                            id="cm"
                            place="top"
                            content="Current Month"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            (filter == "CURRENT_YEAR" && !modalIsOpen)
                              ? "btn btnColorStylesNew btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_YEAR")}
                          data-tooltip-id="cy"
                        >
                          CY
                          <ReactTooltip
                            id="cy"
                            place="top"
                            content="Current Year"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            (filter == "CUSTOM_DURATION" || modalIsOpen)
                              ? "btn btnColorStylesNew btn-sm m-1"
                              : "btn btn-soft-secondary btn-sm m-1"
                          }
                          onClick={() => {
                            setIsOpen(true);
                          }}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock} />
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                      </div>
                    </Col>

                    {LSuserType !== "USER" && (
                      <Col
                        md={2}
                        style={{
                          paddingRight: 0,
                          paddingLeft: 0,
                        }}
                      >
                        <div
                          style={{
                            marginRight: "10px",
                            borderBottom: "1px dashed #DFDEDE",
                          }}
                        >
                          <h4
                            className="card-title mb-1 mt-1"
                            style={{ textAlign: "center" }}
                          >
                            User Selection
                          </h4>
                        </div>
                        <div
                          className="mt-3 mb-3"
                          style={{ textAlign: "center" }}
                        >
                          <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggleDropdown}
                            size="sm"
                          >
                            <DropdownToggle caret 
                            color="primary"
                            className="btnColorStylesNew"
                            style={{border:"0px",color:"black"}}
                            >
                              {selectedOption ? selectedOption : "All Users"}
                              <FaAngleDown style={{ marginLeft: "5px" }} />
                            </DropdownToggle>
                            <DropdownMenu
                              style={{ maxHeight: "200px", overflowY: "auto" }}
                            >
                              <div className="d-flex align-items-center px-3">
                                <FaSearch className="me-2" />
                                <input
                                  type="text"
                                  placeholder="Search users"
                                  value={searchQuery}
                                  onChange={(e) =>
                                    setSearchQuery(e.target.value)
                                  }
                                  className="form-control form-control-sm"
                                />
                              </div>
                              <DropdownItem onClick={() => handleSelect("")}>
                                All Users
                              </DropdownItem>
                              {apiDataUser.filter(filterUsers).map((user) => (
                                <DropdownItem
                                  key={user.userIdTemp}
                                  onClick={() =>
                                    handleSelect(
                                      user.userIdTemp,
                                      user.firstName
                                    )
                                  }
                                >
                                  {user.firstName}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardHeader>

                {/* <CardHeader>
                  <div style={{ display: "flex" }}>
                    <h4 className="card-title">Welcome, </h4>&nbsp;<h4 className="card-title">{userName}</h4>

                  </div>

                  <p className="card-title-desc">
                    Here is Red risk alerts faced by your users in this month
                  </p>
                  <p className="card-title-desc">
                    Note: Risk alert value is between 90 - 100
                  </p>
                </CardHeader> */}
                <CardBody className="m-0 p-0">
                  <div className="table-responsive">
                    <div className="m-3" />
                    <Table bordered className="table mb-0">
                      <thead className="cardHeader">
                        <tr>
                          {LSuserType !== "USER" && (
                            <th
                              style={{ fontSize: "13px", fontWeight: 600 }}
                              data-tooltip-id="First Name"
                            >
                              First Name
                              <ReactTooltip
                                id="First Name"
                                place="top"
                                content="First Name"
                                style={{ background: "black" }}
                              />
                            </th>
                          )}
                          {LSuserType !== "USER" && (
                            <th
                              style={{ fontSize: "13px", fontWeight: 600 }}
                              data-tooltip-id="Username"
                            >
                              Username
                              <ReactTooltip
                                id="username"
                                place="top"
                                content="Username"
                                style={{ background: "black" }}
                              />
                            </th>
                          )}
                          {/* <th>User Name</th> */}
                          <th
                            style={{ fontSize: "13px", fontWeight: 600 }}
                            data-tooltip-id="Ride Name"
                          >
                            Ride Name
                            <ReactTooltip
                              id="Ride Name"
                              place="top"
                              content="Ride Name"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600 }}
                            data-tooltip-id="Device Type"
                          >
                            Device Type
                            <ReactTooltip
                              id="Device Type"
                              place="top"
                              content="Device Type"
                              style={{ background: "black" }}
                            />
                          </th>
                          {/* <th style={{fontSize: "13px",fontWeight: 600}}>Ride Status</th> */}
                          <th
                            style={{ fontSize: "13px", fontWeight: 600 }}
                            data-tooltip-id="Ride Start Time"
                          >
                            Ride Start Time
                            <ReactTooltip
                              id="Ride Start Time"
                              place="top"
                              content="Ride Start Time"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600 }}
                            data-tooltip-id="Risk Alert Name"
                          >
                            Risk Alert Name
                            <ReactTooltip
                              id="Risk Alert Name"
                              place="top"
                              content="Risk Alert Name"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600 }}
                            data-tooltip-id="Risk Alert Value"
                          >
                            Risk Alert Value
                            <ReactTooltip
                              id="Risk Alert Value"
                              place="top"
                              content="Risk Alert Value"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600 }}
                            data-tooltip-id="Risk Alert Time"
                          >
                            Risk Alert Time
                            <ReactTooltip
                              id="Risk Alert Time"
                              place="top"
                              content="Risk Alert Time"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600 }}
                            data-tooltip-id="Risk Alert Level"
                          >
                            Risk Alert Level
                            <ReactTooltip
                              id="Risk Alert Level"
                              place="top"
                              content="Risk Alert Level"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600 }}
                            data-tooltip-id="Risk Alert Type"
                          >
                            Risk Alert Type
                            <ReactTooltip
                              id="Risk Alert Type"
                              place="top"
                              content="Risk Alert Type"
                              style={{ background: "black" }}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLength > 0 ? (
                          (notificationData || []).map((apiData, i) => (
                            <tr key={i}>
                              {LSuserType !== "USER" && (
                                <td
                                  style={{ fontSize: "13px", fontWeight: 400 }}
                                >
                                  {apiData.firstName}
                                </td>
                              )}
                              {LSuserType !== "USER" && (
                                <td
                                  style={{ fontSize: "13px", fontWeight: 400 }}
                                >
                                  {apiData.username}
                                </td>
                              )}
                              {/* <td style={{fontSize: "13px",fontWeight: 400}}>{apiData.username}</td> */}
                              <td style={{ fontSize: "13px", fontWeight: 400 }}>
                                {apiData.rideName}
                              </td>
                              <td style={{ fontSize: "13px", fontWeight: 400 }}>
                                {apiData.deviceType}
                              </td>
                              {/* <td style={{fontSize: "13px",fontWeight: 400}}>{apiData.rideStatus}</td> */}
                              <td style={{ fontSize: "13px", fontWeight: 400 }}>
                                {apiData.rideStartTime}
                              </td>
                              <td style={{ fontSize: "13px", fontWeight: 400 }}>
                                {apiData.riskName}
                              </td>
                              <td style={{ fontSize: "13px", fontWeight: 400 }}>
                                {apiData.riskValue}
                              </td>
                              <td style={{ fontSize: "13px", fontWeight: 400 }}>
                                {apiData.riskTime}
                              </td>
                              <td style={{ fontSize: "13px", fontWeight: 400 }}>
                                {apiData.riskAlertLevel}
                              </td>
                              <td style={{ fontSize: "13px", fontWeight: 400 }}>
                                {apiData.riskAlertLevel === "Medium risk alert"
                                  ? "Amber alert"
                                  : "Red alert"}
                              </td>
                            </tr>
                          ))
                        ) : dataLength === null ? (
                          <tr>
                            {" "}
                            <td colSpan="12">
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.FETCHING_DATA}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            {" "}
                            <td colSpan="12">
                              <div
                                style={{
                                  color: "black",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.NO_RECORDS_FOUND}
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
            {dataLength > 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm"
                  onClick={previous}
                  style={{
                    width: "45px",
                    visibility: prevShow ? "visible" : "hidden",
                  }}
                >
                  Prev
                </button>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="pagination">
                      <a>{pageNumber}</a>
                    </div>
                    <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      of
                    </p>
                    <div className="pagination ">
                      <a>{totalPageCount}</a>
                    </div>
                  </div>
                <button
                  className="btn btn-outline-success btn-sm"
                  onClick={next}
                  style={{
                    width: "45px",
                    visibility: nextShow ? "visible" : "hidden",
                  }}
                >
                  Next
                </button>
              </div>
              )}
            </Col>
          </Row>
          <DateTimePickerPopup
            modalOpen={modalIsOpen}
            modalClose={setIsOpen}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            setFilter={setFilter}
            filter={filter}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NotificationPage;
