import PropTypes from "prop-types";
import React, { useState } from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
// import Authmiddleware from "./routes/middleware/Authmiddleware";
import Authmiddleware from "./AuthMiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import { useEffect } from "react";
import userSessionController from "./controller/UserSession/UserSessionController";
import { SC_EXPIRATION_TIME, SC_FCM_NOTIFICATION_COUNT,SC_FCM_VEHICLECRASHES_COUNT, SC_FCM_NOTIFICATION_TOKEN, SC_PREVIOUS_FCM_NOTIFICATION_TOKEN, SC_LOGIN_USER_ID } from "./constant/StorageConstant";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { getAuth, signInAnonymously } from "firebase/auth";
import messaging from "./controller/FireBasePushNotification/FirsebaseAwt.js";
import { pushType } from "./constant/GeneralConstant.js";
import { ToastContainer } from "react-toastify";
import ToastService from "./components/Common/toastService.js";
import parse from 'html-react-parser';
import ValueFormat from "./util/ValueFormat.js";
import scrollToTop from "./routes/scrollToTop.js";
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
// fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = (props) => {

const [messageData, setMessageData] = useState(getMessaging());
const [notify,setNotify] = useState(false)
const [notificationData,setNotificationData] = useState({currentMessage:"", title:"",subject:""})
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = HorizontalLayout;
        break;
    }
    return layoutCls;
  }


  const receiveMessage = async() => {
    var notiFicationList = [];
    // var riskAlertCountLive = 0;
    try {
      onMessage(messageData, (payload) => {
        // console.log("payload: ",payload.data)

        setNotificationData(prev=> {return{...prev, currentMessage:payload, title:payload.notification.title,subject:payload.notification.body}});

        var pushPayloadType = ValueFormat.parseAsObject(payload.data.pushType);
        if (
          pushPayloadType == pushType.USER_RIDE_DIRECT_RISK_ALERT_TO_ADMIN
        ) {
          var pushPayload = ValueFormat.parseAsObject(JSON.parse(JSON.stringify(payload.data)));
          // console.log("pushPayload :",pushPayload)
          const adminIdList = pushPayload.adminUserIdList.split(',');
          for (let up = 0; up < adminIdList.length; up++) {
            var userLoginType = localStorage.getItem(SC_LOGIN_USER_ID);
            var payloadUserLoginType = adminIdList[up];

            if (payloadUserLoginType == userLoginType) {
              var notificationTitle = {
                title: payload.notification.title,
                body: payload.notification.body,
              };
              ToastService.successmsg(payload.notification.title + ", " + payload.notification.body)

              notiFicationList.push(notificationTitle);

              if(pushPayload.notificationlistview == "NOTIFICATION") {
                let riskAlertCountLive = parseInt(localStorage.getItem(SC_FCM_NOTIFICATION_COUNT)) + parseInt(1);
                localStorage.setItem(SC_FCM_NOTIFICATION_COUNT,riskAlertCountLive);
              } else if (pushPayload.notificationlistview == "VEHICLE_CRASH") {
                let riskAlertCountLive = parseInt(localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT)) + parseInt(1);
                localStorage.setItem(SC_FCM_VEHICLECRASHES_COUNT,riskAlertCountLive);
              }

              setNotify(true)
              setTimeout(() => {
                setNotify(false);
              }, 4000);
            }
          }
        }
      });
    } catch (e) {
      // console.log(e);
    }
  }


  const  activate = async () => {

    try{
    const token = await getToken(messaging, {
      vapidKey:
        "BHESk21DU8CR-mlralt2JScLKV66n-8x1FFYy9456Cak9NX1aiEpwanPmtcNfnwN_DctKduyeM_Ces5z42QLMS8",
    });

    if (token) {
      // console.log("token: "+token);
      const previousToken = localStorage.getItem(SC_FCM_NOTIFICATION_TOKEN);
      localStorage.setItem(SC_PREVIOUS_FCM_NOTIFICATION_TOKEN, previousToken);
      localStorage.setItem(SC_FCM_NOTIFICATION_TOKEN, token);
    } else {
    //  console.log("permission denied");

      //request permission
    }
  } catch (e){
    // console.log("token err :" , e)
  }
  }
  const authenticate = async() =>{
    // const permission = await Notification.requestPermission();
    // if(permission == 'granted') {
      await signInAnonymously(getAuth());
      activate();
    // } else {
      // alert('Need to enable notification permission');
    // }
  }

  const getDatas = async () =>{
    await authenticate();
    await receiveMessage()
  }
  useEffect(()=>{
    getDatas()
  },[messageData])
  useEffect(() => {
    var timeStamp = localStorage.getItem(SC_EXPIRATION_TIME);
    timeStamp = parseInt(timeStamp, 10);
    setInterval(() => {
      if (timeStamp)
        userSessionController.checkUserSessionInformation();
    }, Math.max(timeStamp - new Date().getTime() + 1, 0));
  
  }, []);
  
  const location = useLocation();


  
  useEffect(() => {
    window.scrollTo(0,0);
    // console.log("adddddddddddddddddddddddddd")
}, [location.pathname])

const notifications = Notification.permission;

useEffect(() =>{
  // console.log(notifications, "notificationAllowConsole");
  
}, [notifications])
  

  const Layout = getLayout();

  // Iframe code

  useEffect(() => {
    if (window.top !== window.self) {
        window.top.location = window.self.location;
    }
  }, []);
  
  return (
    <React.Fragment>
      
    <ToastContainer style={{ width: "auto" }} >
      </ToastContainer>

      {/* <Router> */}

      <Routes>
      {/* <scrollToTop/> */}
        {authRoutes.map((route, idx) => (          
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {userRoutes.map((route, idx) => (          
          <Route          
            path={route.path}
            element={
              <div>
                <Authmiddleware authentication={route.auth}>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              </div>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
