import React, { lazy, Suspense } from 'react';
import UserHereMapTest from './UserDetailCrashView';

// const GoogleMapRisk = lazy(() => import('./GoogleMapRisk'));
export default function UserGoogleMapCrashView() {
  return (
    <div>
        <Suspense fallback={<div>Loading...</div>}>
            <UserHereMapTest/>
        </Suspense>
    </div>
  )
}