import React from "react";
import { Nav, NavItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./UserDashboardSubMenu.css";

const UserDashboardSubMenu = () => {
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <h5 style={{ marginTop: "5px",fontSize:"16px" }}>User Dashboard</h5>&nbsp;
        <p style={{position:"relative", top:7, fontSize:"12px"}}>{"(For recent ride summary, Please select any one of the CD / CW / CM options from the filter)"}</p>
        
      </div>
    </React.Fragment>
  );
};

export default UserDashboardSubMenu;
