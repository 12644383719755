import React, { useEffect, useRef, useState } from "react";
import Loader from "../../components/Common/loader";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import { FaAngleDown } from "react-icons/fa";
import Report from "../../controller/Report/Report";
import { Tooltip } from "@mui/material";
import filterClockIconDe from "../../assets/images/common/filterIconClockDeactive.svg"
import filterClockIcon from "../../assets/images/common/filterClockIcon.svg"
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import UserNameIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";
import andIconNew from "../../assets/images/common/androidTableIcon.svg";
import iosIconNew from "../../assets/images/common/iosTableIcon.svg";
import FTwheelerIcon from "../../assets/images/common/twoFourWheelerIcon.svg";
import ActType from "../../assets/images/superAdminIcons/actType.svg";
import ActiveDate from "../../assets/images/superAdminIcons/ActiveDate.svg";
import MDTotal from "../../assets/images/superAdminIcons/MDTotal.svg";
import MDVersion from "../../assets/images/superAdminIcons/MDVersion.svg";
import MDModel from "../../assets/images/superAdminIcons/MDModel.svg";
import MDRegDate from "../../assets/images/superAdminIcons/MDRegDate.svg";
import LDDeviceId from "../../assets/images/superAdminIcons/deviceId.svg";
import MSAppVersion from "../../assets/images/superAdminIcons/MSAppVersion.svg";
import MSLastSession from "../../assets/images/superAdminIcons/MSLastSession.svg";
import MSLastActivity from "../../assets/images/superAdminIcons/MSLastActivity.svg";

export default function AppRides() {
  const [showLoader, setShowLoader] = useState(false);
  const [totalPageCount , setTotalPageCount] = useState(0);
  const [loginType, setLoginType] = useState("");
  const [dataLength, setDataLength] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [apiDataUser, setApiDataUser] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [exceptionFilter, setExceptionFilter] = useState("");
  const [exceptionFilter2, setExceptionFilter2] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectUsername, setSelectUsername] = useState("")
  const [deviceFilter, setDeviceFilter] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [nextShow, setNextShow] = useState(false);
  const [prevShow, setPrevShow] = useState(false);

  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: "",
  });

  const [subScriptionDataList, setSubcriptionDataList] = useState({
    subscriptionName: "",
    totalCount: 100,
    preLoginCrash: 20,
    preLoginOthers: 20,
    postLoginCrash: 20,
    postLoginOthers: 20,
    postLoginPermission: 10,
    postLoginPeriodical: 10,
    startDate: "",
    endDate: "",
    billing: "1 Year",
    orderCount: 0,
  });

  const [searchText, setSearchText] = useState("");
  const inputRef = useRef();
  const [selectedValue, setSelectedValue] = useState("ALL_USER_LIST");

  const pageSize = 10;

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const handleSelect = (userIdTemp, firstName) => {
    setSelectedOption(userIdTemp);
    setSelectUsername(firstName);
    // Do something with the selected option
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIndexStart(0);
      setPageNumber((current) => current - current + 1);
      event.preventDefault();
      inputRef.current.click();
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // getOverAllSummary(0);
  };

  const handleUserClick = (type) => {
    const selectedMapping = {
      ALL:"ALL_USER_LIST",
      ACTIVE: "ACTIVE_USER_LIST",
      INACTIVE: "INACTIVE_USER_LIST",
      UNREGISTERED: "UNREGISTERED_USER_LIST",
      DEACTIVATED: "DEACTIVATED_USER_LIST",
    };
    setSelectedValue(selectedMapping[type]);    
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const getAppExceptionReport = async (indexStart) => {
    setShowLoader(true);
    try {
    var result = await Report.getUserAppExpReport(
      indexStart,
      filter,
      exceptionFilter,
      deviceFilter,
      startDateTime,
      endDateTime,
      pageSize,
      selectedOption
    );
    var tableData = [];
    
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData", resultJSONData);
        setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          var firstName = "",
            deviceType = "",
            uniqueId = "",
            versionNumber = "",
            appVersion = "",
            remote = "",
            userAgent = "",
            errorType = "",
            keyvalueTemp = "",
            batteryLevel = "",
            userIdTemp = "";
          for (let i = 0; i < resultJSONData.length; i++) {
            var userApp = resultJSONData[i];           
            var userDetail = ValueFormat.parseAsObject(userApp.userDetail);
            firstName = ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(userDetail.firstName));
            userIdTemp = userApp.userId || "";
            var totalCounts = ValueFormat.parseAsInt(userApp.totalCount);
            setTotalPageCount(Math.ceil(totalCounts/pageSize));
            setNextShow(indexStart + pageSize < totalCounts);

            if (userApp.deviceType === "ANDROID") {
              deviceType = "Android"
            } else if (userApp.deviceType === "ANDROID_SDK"){
              deviceType = "Android SDK"
            } else if (userApp.deviceType === "IOS") {
              deviceType = "iOS"
            } else if (userApp.deviceType === "IOS_SDK"){
              deviceType = "iOS SDK"
            } else  {
              deviceType = "N/A"
            }
            // deviceType = ValueFormat.parseAsString(
            //   ValueFormat.capitalizeFirstLetter(userApp.deviceType)
            // );
            uniqueId = ValueFormat.parseAsString(userApp.deviceUniqueId);
            versionNumber = ValueFormat.parseAsInt(userApp.deviceVersionNumber);
            appVersion = ValueFormat.parseAsFloat(userApp.appVersion);
            remote = ValueFormat.parseAsFloat(userApp.remoteAddress);
            userAgent = ValueFormat.parseAsString(userApp.userAgent);
            errorType = ValueFormat.parseAsString(
              ValueFormat.capitalizeFirstLetterAndFormat(userApp.errorType)
            );
            batteryLevel = ValueFormat.parseAsFloat(userApp.batteryLevel);
            keyvalueTemp =
              userApp.keyValues.map((val) =>
                ValueFormat.capitalizeFirstLetterAndFormat(val.key)
              ) || [];

            var appExceptiondetails = {
              firstName: firstName,
              deviceType: deviceType,
              uniqueId: uniqueId,
              versionNumber: versionNumber,
              appVersion: appVersion,
              remote: remote,
              userAgent: userAgent,
              errorType: errorType,
              batteryLevel: batteryLevel,
              keyvalueTemp: keyvalueTemp,
              userIdTemp: userIdTemp,
            };
            tableData.push(appExceptiondetails);
            setApiData(tableData);
          }
        }
      } else {
        // var resultJSONCode = resultJSON.error.code;
        // if (resultJSONCode == "E1206" && userType == "USER") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/dashboard";
        //   }, 3500);
        // } else if (resultJSONCode == "E1206" && userType == "ADMIN") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/adminDashboard";
        //   }, 3500);
        // }
        console.log("Status failed");
      }
    } catch (errMes) {
      console.log("Catch error:",errMes);
    } finally{
    setShowLoader(false);
    }
    if (indexStart == 0) {
      setPrevShow(false);
    } else if (indexStart > 1) {
      setPrevShow(true);
    }

  };

  const getUserList = async () => {
    // setShowLoader(true);
    var result = await Report.getUserListReport();
    // setShowLoader(false);
    var userData = [];
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData1", resultJSONData);
        // setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          var firstName = "",
            userIdTemp = "";
          for (let i = 0; i < resultJSONData.length; i++) {
            var userListData = resultJSONData[i];
            firstName = ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(userListData.firstName));
            userIdTemp = userListData.id;

            var userDataListTemp = {
              firstName: firstName,
              userIdTemp: userIdTemp,
            };

            userData.push(userDataListTemp);
            setApiDataUser(userData);
          }
        }
      } else {
        // var resultJSONCode = resultJSON.error.code;
        // if (resultJSONCode == "E1206" && userType == "USER") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/dashboard";
        //   }, 3500);
        // } else if (resultJSONCode == "E1206" && userType == "ADMIN") {
        //   ToastService.errormsg(displayText.ER_1206);
        //   setTimeout(() => {
        //     window.location.href = "/adminDashboard";
        //   }, 3500);
        // }
        console.log("Status failed");
      }
    } catch (errMes) {}
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
      setPageNumber(1);
      if (indexStart == 0) setCount((prev) => prev + 1);
      else setIndexStart(0);
  }, [filter,exceptionFilter,deviceFilter,startDateTime,endDateTime,selectedOption]);

//   useEffect(() => {
//     if (count > 0) getAppExceptionReport(indexStart);
// }, [indexStart,count]);

  // useEffect(() => {
  //   getUserList();
  // }, []);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  function capitalizeLetter  (category){
    return category
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  }  


  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Battery Level" breadcrumbItem="Battery Level" />
        {/* {loginType == "SUPER_ADMIN" ? (
          <span
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Welcome, You are viewing{" "}
            <span
              style={{
                fontSize: "13px",
                fontWeight: 800,
              }}
            >
              {userFirstName}'s
            </span>{" "}
            App Exception
          </span>
        ) : ( */}
            <Row className="my-2 py-2 d-flex justify-content-start align-items-center" >
            <Col xs={3} className="d-flex justify-content-start align-items-center text-black" style={{ height:"0px"}}>
              <h5 className="mb-0" style={{textWrap:"nowrap",}}>Exceptions & Permissions</h5>
            </Col>
            <Col xs={9} className="d-flex justify-content-end align-items-center text-black" style={{gap:10,}}>
              <div className="pb-0 mt-0 mb-0 d-flex justify-content-end ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center", }}>
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == ""
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("")}
                  data-tooltip-id="alle"
                >
                  All
                  <ReactTooltip
                    id="alle"
                    place="top"
                    content="All"
                    style={{ background: "black" }}
                  />
                </button>              
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "PRE_LOGIN"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("PRE_LOGIN")}
                  data-tooltip-id="preLo"
                >
                  Pre Login
                  <ReactTooltip
                    id="preLo"
                    place="top"
                    content="Pre Login"
                    style={{ background: "black" }}
                  />
                </button>
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "POST_LOGIN"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("POST_LOGIN")}
                  data-tooltip-id="postLo"
                >
                  Post Login
                  <ReactTooltip
                    id="postLo"
                    place="top"
                    content="Post Login"
                    style={{ background: "black" }}
                  />
                </button>
              </div>
              <div className="pb-0 mt-0 mb-0 d-flex justify-content-end" style={{ textAlign: "center", }}>
                <div className="mt-0 mb-0 mr-0 ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center" }}>
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == ""
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => setExceptionFilter2("")}
                    data-tooltip-id="alle"
                  >
                    All
                    <ReactTooltip
                      id="alle"
                      place="top"
                      content="All"
                      style={{ background: "black" }}
                    />
                  </button>          
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == "LOGIN_PERMISSION"
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => setExceptionFilter2("LOGIN_PERMISSION")}
                    data-tooltip-id="logPer"
                  >
                    Login Permission
                    <ReactTooltip
                      id="logPer"
                      place="top"
                      content="Login Permission"
                      style={{ background: "black" }}
                    />
                  </button>
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == "PERIODICAL_PERMISSION"
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => setExceptionFilter2("PERIODICAL_PERMISSION")}
                    data-tooltip-id="pdlPer"
                  >
                    Periodical Permission
                    <ReactTooltip
                      id="pdlPer"
                      place="top"
                      content="Periodical Permission"
                      style={{ background: "black" }}
                    />
                  </button>              
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == "CRASH"
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => setExceptionFilter2("CRASH")}
                    data-tooltip-id="crash"
                  >
                    Crash
                    <ReactTooltip
                      id="crash"
                      place="top"
                      content="Crash"
                      style={{ background: "black" }}
                    />
                  </button>              
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == "OTHERS"
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => setExceptionFilter2("OTHERS")}
                    data-tooltip-id="others"
                  >
                    Others
                    <ReactTooltip
                      id="others"
                      place="top"
                      content="Others"
                      style={{ background: "black" }}
                    />
                  </button>    
                </div>
              </div>
              <div className="pb-0 d-flex justify-content-end">
                <div className="ms-auto pe-0 ">
                  <div style={{ float: "right",  }}>
                  <Tooltip title="All">
                    <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "ALL" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => {
                          setCount(0);
                          setStartDateTime("");
                          setEndDateTime("");
                          setFilters({
                            ...filters,
                            durationFilter: "ALL",
                          });
                        }}
                      >
                        All                           
                      </button>
                      </Tooltip>
                      <Tooltip title="Current Day">
                      <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_DAY" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setCount(0);
                          setStartDateTime("");
                          setEndDateTime("");
                          setFilters({
                            ...filters,
                            durationFilter: "CURRENT_DAY",
                          });
                        }}
                      >
                        CD                           
                      </button>
                      </Tooltip>
                      {" "}
                      <Tooltip title="Current Week">
                      <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_WEEK" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setCount(0);
                          setStartDateTime("");
                          setEndDateTime("");
                          setFilters({
                            ...filters,
                            durationFilter: "CURRENT_WEEK",
                          });
                        }}
                      >
                        CW                           
                      </button>
                      </Tooltip>
                      {" "}
                      <Tooltip title="Current Month">
                      <button
                        type="button"                           
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_MONTH" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setCount(0);
                          setStartDateTime("");
                          setEndDateTime("");
                          setFilters({
                            ...filters,
                            durationFilter: "CURRENT_MONTH",
                          });
                        }}
                      >
                        CM
                        
                      </button>
                      </Tooltip>{" "}
                      <Tooltip title="Current Year">
                      <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_YEAR" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setCount(0);
                          setStartDateTime("");
                          setEndDateTime("");
                          setFilters({
                            ...filters,
                            durationFilter: "CURRENT_YEAR",
                          });
                        }}
                      >
                        CY                            
                      </button>
                      </Tooltip>{" "}
                      <Tooltip title="Custom Date Selection">
                      <button
                        type="button"
                        className={
                          filters.durationFilter == "CUSTOM_DURATION" ||
                          modalIsOpen
                            ? "btn btnColorStylesNew btn-sm"
                            : "btn btnColorStylesSecondaryNew btn-sm"
                        }
                        onClick={() => {
                          setIsOpen(true);
                          setCount(1);
                          // setFilters({
                          //   ...filters,
                          //   durationFilter: "CUSTOM_DURATION",
                          // });
                        }}
                      
                      >
                        {filter == "CUSTOM_DURATION" || modalIsOpen ? (
                          <img
                            src={filterClockIcon}
                            alt="clock"
                            style={{
                              height: "18px",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          />
                        ) : (
                          <img
                            src={filterClockIconDe}
                            alt="clock"
                            style={{
                              height: "18px",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          />
                        )}                           
                      </button>{" "}
                      </Tooltip>
                  </div>
                </div>
                {/* <ExportScreenPdf fileName={userFirstName + "'s" + " Dashboard"} /> */}
              </div>
            </Col>
          </Row>
          {/* )}  */}

          <Col xl={12} lg={12} md={12}>
                  <Row >
                    <Card style={{height:"auto"}}>
                      <CardBody className="body_text" style={{ padding: "10px", height: "auto", overflow:"hidden" }} >
                        <div className="row mt-2" style={{ display: "flex", justifyContent: "space-between", }} >
                          <div className="col-sm" style={{ display: "flex" }}>
                            <div style={{ height: 70, width: 70 }}>                            
                              <CircularProgressbar
                                value={subScriptionDataList.preLoginCrash ? subScriptionDataList.preLoginCrash : "-"}
                                text={`${subScriptionDataList.preLoginCrash == "NaN" ? "0" : subScriptionDataList.preLoginCrash}%`}
                                styles={buildStyles({
                                  width: "20px",
                                  textColor: "#000",
                                  pathColor: "#19543e",
                                  trailColor: "#0095FF",
                                })}
                              ></CircularProgressbar>
                            </div>
                            <span style={{ height: 70, display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px", fontSize: "14px", fontWeight: 700, whiteSpace: "nowrap", }} >
                                Pre Login Crash
                              <br />
                              <span>
                                {subScriptionDataList.preLoginCrash} /{" "}
                                {subScriptionDataList.totalCount}
                              </span>
                            </span>
                          </div>                        

                          <div className="col-sm" style={{ display: "flex" }}>
                            <div style={{ height: 70, width: 70 }}>
                            
                              <CircularProgressbar
                                value={subScriptionDataList.preLoginOthers}
                                text={`${subScriptionDataList.preLoginOthers == "NaN" ? "0" : subScriptionDataList.preLoginOthers}%`}
                                styles={buildStyles({
                                  width: "20px",
                                  textColor: "#000",
                                  pathColor: "#FFBF00",
                                  trailColor: "#0095FF",
                                })}
                              ></CircularProgressbar>
                            </div>
                            <span style={{ height: 70, display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px", whiteSpace: "nowrap", fontSize: "14px", fontWeight: 700, }} >
                                Pre Login Others
                              <br />
                              <span>
                                {subScriptionDataList.preLoginOthers +
                                  " / " +
                                  subScriptionDataList.totalCount}
                              </span>
                            </span>
                          </div>                        

                          <div className="col-sm" style={{ display: "flex" }}>
                            <div style={{ height: 70, width: 70 }}>                            
                              <CircularProgressbar
                                value={subScriptionDataList.postLoginCrash}
                                text={`${subScriptionDataList.postLoginCrash == "NaN" ? "0" : subScriptionDataList.postLoginCrash}%`}
                                styles={buildStyles({
                                  width: "20px",
                                  textColor: "#000",
                                  pathColor: "#E21212",
                                  trailColor: "#0095FF",
                                })}
                              ></CircularProgressbar>
                            </div>
                            <span style={{ height: 70, display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px", fontSize: "14px", fontWeight: 700, whiteSpace: "nowrap", }} >
                              Post Login Crash
                              <br />
                              <span>
                                {subScriptionDataList.postLoginCrash} /{" "}
                                {subScriptionDataList.totalCount}
                              </span>
                            </span>
                          </div>

                          <div className="col-sm mt-sm-2 mt-md-2 mt-xl-0" style={{ display: "flex" }}>
                            <div style={{ height: 70, width: 70 }}>                            
                              <CircularProgressbar
                                value={subScriptionDataList.postLoginOthers}
                                text={`${subScriptionDataList.postLoginOthers == "NaN" ? "0" : subScriptionDataList.postLoginOthers}%`}
                                styles={buildStyles({
                                  width: "20px",
                                  textColor: "#000",
                                  pathColor: "#df12e2",
                                  trailColor: "#0095FF",
                                })}
                              ></CircularProgressbar>
                            </div>
                            <span style={{ height: 70, display: "flex",flexDirection: "column", justifyContent: "center", marginLeft: "10px", fontSize: "14px", fontWeight: 700, whiteSpace: "nowrap", }} >
                              Post Login others
                              <br />
                              <span>
                                {subScriptionDataList.postLoginOthers} /{" "}
                                {subScriptionDataList.postLoginOthers}
                              </span>
                            </span>
                          </div>

                          <div className="col-sm mt-sm-2 mt-md-2 mt-xl-0" style={{ display: "flex" }}>
                            <div style={{ height: 70, width: 70 }}>                            
                              <CircularProgressbar
                                value={subScriptionDataList.postLoginPermission}
                                text={`${subScriptionDataList.postLoginPermission == "NaN" ? "0" : subScriptionDataList.postLoginPermission}%`}
                                styles={buildStyles({
                                  width: "20px",
                                  textColor: "#000",
                                  pathColor: "#12e2bf",
                                  trailColor: "#0095FF",
                                })}
                              ></CircularProgressbar>
                            </div>
                            <span style={{ height: 70, display: "flex",flexDirection: "column", justifyContent: "center", marginLeft: "10px", fontSize: "14px", fontWeight: 700, whiteSpace: "nowrap", }} >
                              Post Login Permission
                              <br />
                              <span>
                                {subScriptionDataList.postLoginPermission} /{" "}
                                {subScriptionDataList.totalCount}
                              </span>
                            </span>
                          </div>

                          <div className="col-sm mt-sm-2 mt-md-2 mt-xl-0" style={{ display: "flex" }}>
                            <div style={{ height: 70, width: 70 }}>                            
                              <CircularProgressbar
                                value={subScriptionDataList.postLoginPeriodical}
                                text={`${subScriptionDataList.postLoginPeriodical == "NaN" ? "0" : subScriptionDataList.postLoginPeriodical}%`}
                                styles={buildStyles({
                                  width: "20px",
                                  textColor: "#000",
                                  pathColor: "#e28f12",
                                  trailColor: "#0095FF",
                                })}
                              ></CircularProgressbar>
                            </div>
                            <span style={{ height: 70, display: "flex",flexDirection: "column", justifyContent: "center", marginLeft: "10px", fontSize: "14px", fontWeight: 700, whiteSpace: "nowrap", }} >
                              Post Login Periodical
                              <br />
                              <span>
                                {subScriptionDataList.postLoginPeriodical} /{" "}
                                {subScriptionDataList.totalCount}
                              </span>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                
          </Col>   

              <Row style={{marginTop:"-20px", }}>
                <Col xl={12} md={12} sm={12}>
                  <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",}}>
                      <div className="my-3" style={{ display: "flex", justifyContent: "center", flexDirection: "row", height: "40px",marginTop: "-50px", }}>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width:"250px"}} >
              <input type="search" style={{ border: " 1px solid #EEEBEB", width: "100%", height: "80%", }}
                value={searchText}
                onKeyPress={handleKeyPress}
                onChange={(e) => { setSearchText(e.target.value); }}
                placeholder="Search"
              />
            </div>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }}  >
                          <button ref={inputRef}
                            style={{ marginLeft: "5px", height: "80%", display: "flex", justifyContent: "center", flexDirection: "column", }}
                            className="btn btnColorStylesNew me-3"
                            type="button"
                            onClick={handleSearch}
                          >
                            <i className="bx bx-search-alt align-middle"></i>
                          </button>
                        </div>
                      </div>
                      <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", gap:10}}>
                        <div className="mt-0 mb-0 mr-0 ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center",  }}>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == ""
                                ? "btn btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("")}
                            data-tooltip-id="allBl"
                          >
                            All
                            <ReactTooltip
                              id="allBl"
                              place="top"
                              content="All"
                              style={{ background: "black" }}
                            />
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "ANDROID"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("ANDROID")}
                            data-tooltip-id="androidBl"
                          >
                            Android
                            <ReactTooltip
                              id="androidBl"
                              place="top"
                              content="Android"
                              style={{ background: "black" }}
                            />
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "IOS"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("IOS")}
                            data-tooltip-id="iosBl"
                          >
                            iOS
                            <ReactTooltip
                              id="iosBl"
                              place="top"
                              content="iOS"
                              style={{ background: "black" }}
                            />
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "WEB"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("WEB")}
                            data-tooltip-id="Webt"
                          >
                            iOS SDK
                            <ReactTooltip
                              id="Webt"
                              place="top"
                              content="Web"
                              style={{ background: "black" }}
                            />
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "WEB"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("WEB")}
                            data-tooltip-id="Webt"
                          >
                            Android SDK
                            <ReactTooltip
                              id="Webt"
                              place="top"
                              content="Web"
                              style={{ background: "black" }}
                            />
                          </button>
                        </div>
                        <div className="my-0" style={{ height: "0px", display: "flex", alignItems:"center", marginTop: "0x",  }}>
                          <div className=" mr-0 ms-auto pe-0  border-secondary" >
                            <div style={{ float: "right" }}>
                            {["ALL","ACTIVE", "INACTIVE", "UNREGISTERED", "DEACTIVATED"].map((type) => (
                              <button
                                key={type}
                                type="button"
                                style={{
                                  fontWeight: 700,
                                  // backgroundColor: selectedValue === type ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm", // Highlight selected
                                  // color: selectedValue === type ? "#fff" : "#000",
                                  marginRight:"5px"
                                }}
                                className={`btn btn-sm ${
                                  selectedValue === `${type}_USER_LIST` ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm"
                                }`}
                                id={type}
                                onClick={() => handleUserClick(type)} // Pass type to handler
                              >
                                {type.charAt(0) + type.slice(1).toLowerCase()} {/* Format button label */}
                              </button>
                            ))}
                            </div>
                          </div>
                        </div>
                      </div>
                 
                  </div>

                  <div className="mt-0" style={{ overflowX: "auto",border:"1px solid #DFDEDE"}} >
                    <Table className="table table-bordered mb-0" style={{ borderCollapse: "collapse" }}>
                      <thead className="cardHeader">
                        <tr>
                          <th className="cardHeader" style={{ fontSize: "13px", fontWeight: 600, padding: "14px", position: "sticky", left: -2, zIndex: 2, background:"white" }} >
                            User Details                            
                          </th>
                          <th className="cardHeader" style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center", position: "sticky", left: 200, zIndex: 2, background:"white", }} >
                            Device Details
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                            App Details
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                            Exception / Permission Details
                          </th>                         
                        </tr>
                      </thead>

                      <tbody>
                        {/* {dataLength > 0 ? (
                          (summaryDataList || []).map((apiData, index) => ( */}
                            <tr 
                            // key={index}
                            >
                              <td style={{ fontSize: "13px", verticalAlign: "middle", position: "sticky", left: -2, zIndex: 2, backgroundColor: "white", }} >
                                <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={UserNameIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                  </div>                          
                                  <div className="second-div-flex" >     
                                      <span className="span-tag-name" > Rajendra Prasad
                                        {/* {apiData.firstName}  */}
                                        </span>
                                  </div>
                                  <div className="div-spacing" > | </div>
                                  <div>
                                    <p className="p-tag-username"> 007
                                      {/* {apiData.username}  */}
                                      </p>
                                  </div>
                                  <div>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    > Active
                                    {/* {apiData.userStatus} */}
                                    </button>
                                  </div>
                                </div>

                                <div  className="div-flex-margin" >
                                    <div style={{ display: "flex", marginLeft: "3px", }}>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                        {apiData.deviceType == "ANDROID" ? (
                                          <img src={andIconNew} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} /> 
                                        ) : apiData.deviceType == "IOS" ? (
                                          <img src={iosIconNew} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                        ) : (
                                          "-"
                                        )}
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                        {(apiData.deviceType == "ANDROID") || (apiData.deviceType == "IOS" ) ? "Mobile" : (apiData.deviceType == "ANDROID_SDK") || (apiData.deviceType == "IOS_SDK" )  ?  "SDK" : "Other"}
                                      </div>
                                    </div>     
                                    <div className="div-spacing" > | </div>
                                    <div style={{ display: "flex", marginLeft: "3px", }}>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                        <img src={FTwheelerIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                        {apiData.vehicleType === "FOUR_WHEELER" 
                                          ? "4W" 
                                          : apiData.vehicleType === "TWO_WHEELER" 
                                          ? "2W" 
                                          : "-"}
                                      </div>
                                    </div>
                                </div>

                                <div>
                                  <div className="div-flex-reg-act">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ActType} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Act Type : </p>
                                    {/* <p className="p-tag-text-left" > {capitalizeLetter(apiData.usernameType)} </p> */}
                                  </div>

                                  <div className="div-flex-reg-act">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ActiveDate} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Act Date : </p>
                                    {/* <p className="p-tag-text-left" > {UtilDateTime.formatDateInt(apiData.createdAtToTimeZone)} | {formatTime(apiData.createdAtToTimeZone)}</p> */}
                                  </div>
                                </div>                    
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Total:  </p>
                                    <p className="p-tag-text-left" >
                                    {/* {apiData.totalDeviceCount} | */}
                                    </p>
                                  </div>
                                  <div className="div-flex-reg-detail">
                                    <p className="p-tag-text-right" style={{marginLeft:"10px"}} > Active: </p>
                                    <p className="p-tag-text-left" >
                                    {/* {apiData.activeDeviceCount} */}
                                    </p>
                                  </div>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={LDDeviceId} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Device Id: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.lastUniqueId} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDVersion} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Version: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.lastVersionNumber} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDModel} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Model: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.lastModelName} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDRegDate} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Reg Date: </p>
                                  <p className="p-tag-text-left" >
                                    {/* {UtilDateTime.formatDateInt(apiData.DeviceLastCreatedAtToTimeZone)} | {formatTime(apiData.DeviceLastCreatedAtToTimeZone)} */}
                                  </p>
                                </div>
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Total: </p>
                                    <p className="p-tag-text-left" >
                                    {/* {apiData.totalSessionCount} | */}
                                    </p>
                                  </div>
                                  <div className="div-flex-reg-detail">
                                      <p className="p-tag-text-right" style={{marginLeft:"10px"}} > Active: </p>
                                      <p className="p-tag-text-left" >
                                      {/* {apiData.activeSessionCount} */}
                                      </p>
                                  </div>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={LDDeviceId} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Device Id: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.sessionLastDeviceId} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSAppVersion} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right"> App Version: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.sessionLastAppVersion} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSLastSession} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Last Session: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.MobileLastSessionAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.MobileLastSessionAtToTimeZone)} | {apiData.MobileLastSessionAtToTimeZone == "-" ? "-" : formatTime(apiData.MobileLastSessionAtToTimeZone)} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSLastActivity} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Last Activity: </p>
                                  <p className="p-tag-text-left" >
                                    {/* {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.sessionLastActivityAtToTimeZone)} | {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : formatTime(apiData.sessionLastActivityAtToTimeZone)}    */}
                                  </p>
                                </div>
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                  <div>
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Total: </p>
                                    <p className="p-tag-text-left" >
                                    {/* {apiData.totalSessionCount} | */}
                                    </p>
                                  </div>
                                  <div className="div-flex-reg-detail">
                                      <p className="p-tag-text-right" style={{marginLeft:"10px"}} > Active: </p>
                                      <p className="p-tag-text-left" >
                                      {/* {apiData.activeSessionCount} */}
                                      </p>
                                  </div>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={LDDeviceId} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Device Id: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.sessionLastDeviceId} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSAppVersion} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right"> App Version: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.sessionLastAppVersion} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSLastSession} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Last Session: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.MobileLastSessionAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.MobileLastSessionAtToTimeZone)} | {apiData.MobileLastSessionAtToTimeZone == "-" ? "-" : formatTime(apiData.MobileLastSessionAtToTimeZone)} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSLastActivity} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Last Activity: </p>
                                  <p className="p-tag-text-left" >
                                    {/* {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.sessionLastActivityAtToTimeZone)} | {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : formatTime(apiData.sessionLastActivityAtToTimeZone)}    */}
                                  </p>
                                </div>
                                </div>
                                <div>
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Total: </p>
                                    <p className="p-tag-text-left" >
                                    {/* {apiData.totalSessionCount} | */}
                                    </p>
                                  </div>
                                  <div className="div-flex-reg-detail">
                                      <p className="p-tag-text-right" style={{marginLeft:"10px"}} > Active: </p>
                                      <p className="p-tag-text-left" >
                                      {/* {apiData.activeSessionCount} */}
                                      </p>
                                  </div>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={LDDeviceId} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Device Id: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.sessionLastDeviceId} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSAppVersion} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right"> App Version: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.sessionLastAppVersion} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSLastSession} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Last Session: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.MobileLastSessionAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.MobileLastSessionAtToTimeZone)} | {apiData.MobileLastSessionAtToTimeZone == "-" ? "-" : formatTime(apiData.MobileLastSessionAtToTimeZone)} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSLastActivity} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Last Activity: </p>
                                  <p className="p-tag-text-left" >
                                    {/* {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.sessionLastActivityAtToTimeZone)} | {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : formatTime(apiData.sessionLastActivityAtToTimeZone)}    */}
                                  </p>
                                </div>
                                </div>
                                <div>
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Total: </p>
                                    <p className="p-tag-text-left" >
                                    {/* {apiData.totalSessionCount} | */}
                                    </p>
                                  </div>
                                  <div className="div-flex-reg-detail">
                                      <p className="p-tag-text-right" style={{marginLeft:"10px"}} > Active: </p>
                                      <p className="p-tag-text-left" >
                                      {/* {apiData.activeSessionCount} */}
                                      </p>
                                  </div>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={LDDeviceId} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Device Id: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.sessionLastDeviceId} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSAppVersion} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right"> App Version: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.sessionLastAppVersion} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSLastSession} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Last Session: </p>
                                  <p className="p-tag-text-left" >
                                  {/* {apiData.MobileLastSessionAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.MobileLastSessionAtToTimeZone)} | {apiData.MobileLastSessionAtToTimeZone == "-" ? "-" : formatTime(apiData.MobileLastSessionAtToTimeZone)} */}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSLastActivity} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Last Activity: </p>
                                  <p className="p-tag-text-left" >
                                    {/* {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.sessionLastActivityAtToTimeZone)} | {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : formatTime(apiData.sessionLastActivityAtToTimeZone)}    */}
                                  </p>
                                </div>
                                </div>
                                </div>
                              </td>

                            </tr>
                          {/* ))
                        ) : dataLength === 0 ? ( 
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "red",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                  fontSize:"13px"
                                }}
                              >
                                {displayText.DATA_NOT_AVAILABLE}
                              </div>
                            </td>
                          </tr>
                        ) : dataLength === null ? (
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.FETCHING_DATA}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}  */}
                      </tbody>
                    </Table>          
                  </div>  
                </Col>
              </Row>



        {/* <Row>
          <Col className="col-12 mt-2">
            <Card>
              <CardHeader style={{ padding: "0px" }}>
                <Row>
                  <Col
                    md={4}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        borderBottom: "1px dashed #DFDEDE",
                      }}
                    >
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Error Selection
                      </h4>
                    </div>
                    <div className="mt-2 mb-2" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          exceptionFilter == ""
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("")}
                        data-tooltip-id="alle"
                      >
                        All
                        <ReactTooltip
                          id="alle"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "PRE_INSTALLATION"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("PRE_INSTALLATION")}
                        data-tooltip-id="preIn"
                      >
                        Pre Install
                        <ReactTooltip
                          id="preIn"
                          place="top"
                          content="Pre Installation"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "POST_INSTALLATION"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("POST_INSTALLATION")}
                        data-tooltip-id="postin"
                      >
                        Post Install
                        <ReactTooltip
                          id="postin"
                          place="top"
                          content="Post Installation"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "PRE_LOGIN"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("PRE_LOGIN")}
                        data-tooltip-id="preLo"
                      >
                        Pre Login
                        <ReactTooltip
                          id="preLo"
                          place="top"
                          content="Pre Login"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "POST_LOGIN"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("POST_LOGIN")}
                        data-tooltip-id="postLo"
                      >
                        Post Login
                        <ReactTooltip
                          id="postLo"
                          place="top"
                          content="Post Login"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Device Selection
                      </h4>
                    </div>
                    <div  style={{ textAlign: "center",marginTop:"12px"}}>
                      <button
                        type="button"
                        className={
                          deviceFilter == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "ANDROID"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("ANDROID")}
                        data-tooltip-id="androidBl"
                      >
                        Android
                        <ReactTooltip
                          id="androidBl"
                          place="top"
                          content="Android"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "IOS"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("IOS")}
                        data-tooltip-id="iosBl"
                      >
                        iOS
                        <ReactTooltip
                          id="iosBl"
                          place="top"
                          content="iOS"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "WEB"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("WEB")}
                        data-tooltip-id="Webt"
                      >
                        Web
                        <ReactTooltip
                          id="Webt"
                          place="top"
                          content="Web"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Duration Selection
                      </h4>
                    </div>
                    <div  style={{ textAlign: "center",marginTop:"12px" }}>
                      <button
                        type="button"
                        className={
                          filter == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_DAY" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_DAY")}
                        data-tooltip-id="cd"
                      >
                        CD
                        <ReactTooltip
                          id="cd"
                          place="top"
                          content="Current Day"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_WEEK" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_WEEK")}
                        data-tooltip-id="cw"
                      >
                        CW
                        <ReactTooltip
                          id="cw"
                          place="top"
                          content="Current Week"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_MONTH" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_MONTH")}
                        data-tooltip-id="cm"
                      >
                        CM
                        <ReactTooltip
                          id="cm"
                          place="top"
                          content="Current Month"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_YEAR" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_YEAR")}
                        data-tooltip-id="cy"
                      >
                        CY
                        <ReactTooltip
                          id="cy"
                          place="top"
                          content="Current Year"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CUSTOM_DURATION" || modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        data-tooltip-id="dfdf"
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <ReactTooltip
                          id="dfdf"
                          place="top"
                          content="Custom Date Selection"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>
                  <Col
                    md={2}
                    style={{
                      paddingRight: 0, paddingLeft: 0,
                    }}
                  >
                    <div style={{ marginRight: "10px", borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        User Selection
                      </h4>
                    </div>
                    <div  style={{ textAlign: "center",marginTop:"12px"}}>
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                        size="sm"
                      >
                        <DropdownToggle
                          caret
                          color="primary"
                        >
                          {selectUsername ? selectUsername : "All Users"}
                          <FaAngleDown style={{ marginLeft: "5px" }} />
                        </DropdownToggle>
                        <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto" }}>
                          <DropdownItem onClick={() => handleSelect(" ")}>
                            All Users
                          </DropdownItem>
                          {apiDataUser.map(user => (
                            <DropdownItem key={user.userIdTemp} onClick={() => handleSelect(user.userIdTemp, user.firstName)}>
                              {user.firstName}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "0px" }}>
                <Row>
                  <Col xl={12} md={12} sm={12}>
                    <div className="table-responsive mt-3">
                      <table className="table table-bordered mb-0">
                        <thead className="cardHeader">
                          <tr>
                            <th
                              data-tooltip-id="Fn"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              First Name
                              <ReactTooltip
                                id="Fn"
                                place="top"
                                content="First Name"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="err"
                              style={{fontSize: "13px",fontWeight: 600}}
                            >
                              Error
                              <ReactTooltip
                                id="err"
                                place="top"
                                content="Error Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="deviceType"
                              style={{fontSize: "13px",fontWeight: 600}}
                            >
                              Device Type
                              <ReactTooltip
                                id="deviceType"
                                place="top"
                                content="Device Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="appversion"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              App Version
                              <ReactTooltip
                                id="appversion"
                                place="top"
                                content="App Version"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="versionNumber"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              Device Version Number
                              <ReactTooltip
                                id="versionNumber"
                                place="top"
                                content="Device Version Number"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="uniqueId"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              Device Unique Id
                              <ReactTooltip
                                id="uniqueId"
                                place="top"
                                content="Device Unique Id"
                                style={{ background: "black" }}
                              />
                            </th>
                            
                            
                            <th
                              data-tooltip-id="rAddress"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              Remote
                              <ReactTooltip
                                id="rAddress"
                                place="top"
                                content="Remote Address"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="UserAgent"
                              style={{fontSize: "13px",fontWeight: 600,  }}
                            >
                              User Agent
                              <ReactTooltip
                                id="UserAgent"
                                place="top"
                                content="User Agent"
                                style={{ background: "black" }}
                              />
                            </th>
                            
                            <th
                              data-tooltip-id="batterlevel"
                              style={{fontSize: "13px",fontWeight: 600, }}
                            >
                              Battery Level
                              <ReactTooltip
                                id="batterylevel"
                                place="top"
                                content="Battery Level"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataLength > 0 ? (
                            apiData.map((apiDataTemp, i) => (
                              <tr key={i}>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.firstName}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.errorType}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.deviceType}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.appVersion}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.versionNumber}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.uniqueId}
                                  </span>
                                </td>
                                
                                
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.remote}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.userAgent}
                                  </span>
                                </td>
                                
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.batteryLevel}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : dataLength === null ? (
                            <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.NO_RECORDS_FOUND}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <div className="d-flex justify-content-between mb-3">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
                <div style={{display:"flex",justifyContent:"space-between"}}>
                <div className="pagination">
                  <a>{pageNumber}</a>
                </div>
                <p style={{marginTop:"6px",marginLeft:"5px",marginRight:"5px"}} >of</p>             
              <div className="pagination ">                  
             <a>{totalPageCount}</a>             
           </div> 
           </div>
              ) : null}
              {dataLength > 0 ? (
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility: nextShow ? "visible" : "hidden",
                  float: "hidden",
                }}
              >
                Next
              </button>
                ) : null}
            </div>
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}
