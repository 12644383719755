import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Progress,
  Row,
  Table,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Common/loader";
import ValueFormat from "../../util/ValueFormat";
import ViewPdf from "../../components/ExportScreenPdf/indexReact2Pdf";
import UtilDateTime from "../../util/UtilDateTime";
import { crashViewListTopRow } from "../../common/data/crashViewList";
import CountUp from "react-countup";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import UserNameIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";
import iosIconNew from "../../assets/images/common/iosTableIcon.svg";
import andIconNew from "../../assets/images/common/androidTableIcon.svg";
import FTwheelerIcon from "../../assets/images/common/twoFourWheelerIcon.svg";
import ActiveDateIcon from "../../assets/images/common/ActiveDateIcon.svg";
import i4 from "../../assets/images/adminDashboard/totalRides.svg";
import locationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIcon.svg";
import driverTypeIcon from "../../assets/images/adminDashboard/myRidesPageIcons/driverTypeIcon.svg";
import passengerTypeIcon from "../../assets/images/adminDashboard/myRidesPageIcons/passengerTypeIcon.svg";
import locationImageCrash from "../../assets/images/common/crashLocationImage.svg";
import AddressImageCrash from "../../assets/images/common/addressIconCrash.svg";
import ZipcodeImageCrash from "../../assets/images/common/zipcodeCrashIcon.svg";
import LatImage from "../../assets/images/common/latCrashImage.svg";
import LongImage from "../../assets/images/common/LongImageCrash.svg";
import crashIcon from "../../assets/images/common/crashIcon.svg";
import crashIntensityIcon from "../../assets/images/common/crashIntensityIcon.svg";
import crashValueIcon from "../../assets/images/common/crashValueIcon.svg";
import contactIcon from "../../assets/images/common/contactIcon.svg";
import actualSpeedIcon from "../../assets/images/common/actualSpeedIcon.svg";
import speedLimitICon from "../../assets/images/common/speedLimitICon.svg";
import distanceIcon from "../../assets/images/common/distanceIcon.svg";
import calendarIcon from "../../assets/images/common/calendarIcon.svg";
import clockIcon from "../../assets/images/common/clockIcon.svg";
import AdminDashboardCrashCtrlr from "../../controller/AdminDashboardCrashCtrlr";
import { displayText } from "../../constant/MessageConstant";
import { SC_DIVISION_ID } from "../../constant/StorageConstant";
const divId = localStorage.getItem(SC_DIVISION_ID);

const HereMapTest = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [topRow, setTopRow] = useState(crashViewListTopRow);
  const [locationParams, setLocationParams] = useState({
    rideId: "",
    userId: "",
    divisionId: "",
  });
  // console.log(topRow, "toprowtoprow");
  const [dataLength, setDataLength] = useState(0);
  const [apiData, setApiData] = useState([]);
  const location = useLocation();
  const history = useNavigate();

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  const getErrorDataWithinRange = (data, range) => {
    const errorData = data.filter((entry) => entry.category === "ERROR_DATA");
  
    const result = [];
    let lastErrorIndex = -1;
  
    for (let i = 0; i < errorData.length; i++) {
      const errorEntry = errorData[i];
      const errorKm = parseFloat(errorEntry.distanceInKm);
  
      // Determine the start index for data collection
      let startIndex = lastErrorIndex + 1; // Start from the next entry after the last ERROR_DATA
      
      if (i === 0) {
        startIndex = 0; // If it's the first ERROR_DATA, start from the beginning
      }
  
      // Collect data up to the current ERROR_DATA entry but not exceeding 1 km
      let dataWithinRange = [];
      for (let j = startIndex; j < data.length; j++) {
        const entry = data[j];                
        const entryKm = parseFloat(entry.distanceInKm);   
  
        if (entry === errorEntry || entryKm >= errorKm - range) {
          dataWithinRange.push(entry);
          if (entry === errorEntry) {
            break; // Stop once we've included the current ERROR_DATA entry
          }
        }
      }
  
      // Extract latitude and longitude from the filtered data
      const latLngData = dataWithinRange.map((entry, i, array) => {      
         
        return {  // Return the object inside curly braces
          latitude: entry.latitude,
          longitude: entry.longitude,
          speed: entry.speed,
          category: entry.category,
          risk: entry.risk,
          speedLimit: entry.speedLimit,
          kilometer: entry.distanceInKm,
          time: entry.createdAtToTimeZone,
          previousSpeed: entry.previousSpeed,
          accidentType: entry.accidentType,
          violationRemarks:
            entry.category === "ERROR_DATA" || i === array.length - 1
              ? "Vehicle crashed"
              : entry.previousSpeed !== null && entry.speed - entry.previousSpeed >= 40
              ? `The driver forcefully accelerated the vehicle from ${entry.previousSpeed} km/hr to ${entry.speed} km/hr.`
              : entry.risk > 90
              ? "The Driver Violated the Traffic Rules."
              : entry.previousSpeed !== null && entry.speed - entry.previousSpeed < -40
              ? `The Driver forcefully braked the vehicle from ${entry.previousSpeed} km/hr to ${entry.speed} km/hr.`
              : entry.speed > entry.speedLimit
              ? `The driver violated the speed limit. For instance, the actual speed limit in the range was ${
                  entry.speedLimit === "0.00" ? "40.00" : entry.speedLimit
                } km/hr, but the driver's speed was ${entry.speed} km/hr.`
              : "The driver followed the rules at that instant.",
        };
      });
  
      // Add to the result array
      result.push({
        errorEntry,
        dataWithinRange: latLngData,
      });
  
      // Update the last error index
      lastErrorIndex = data.indexOf(errorEntry);
    }
  
    return result;
  };
  
  
  

  // Specify the range in kilometers
  const rangeKm = 1;

  const [errorDataWithinRange, setErrorDataWithinRange] = useState([]);
  
  const getRiskAlertsDataList1 = async () => {
    setShowLoader(true);
    try {     
        
      var result = await AdminDashboardCrashCtrlr.getCrash1KmErrorData(
        locationParams.divisionId,
      locationParams.rideId,
      locationParams.userId,
      );
      var resultJSON = JSON.parse(result);
      // console.log(result, "resultJSONgdfgdfgdf");
      var status = resultJSON.status;

      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;

        // console.log(resultJSONData, "resultJSONDatadgdfgdfgdfg")
        if (resultJSONData.length > 0) {
          const errorDataWithinRange = getErrorDataWithinRange(
            resultJSONData,
            rangeKm
          );
          setErrorDataWithinRange(errorDataWithinRange);
          
        } else {
          // console.log("Data Didn't Fetch");
        }
      } else {
        // console.log("Status Failed");
      }
    } catch (e) {
      // console.log("catch errr", e);
    } finally {
      setShowLoader(false);
    }
  };
  

 

  useEffect(() => {
    if (location.state != null) {
      const { rideId, userId, divisionId, } = location.state;
      setLocationParams((prev) => {
        return {
          ...prev,
          rideId: rideId,
          userId: userId,
          divisionId: divisionId,
        };
      });
    } else {
      history("/myRides");
    }
  }, []);

  


  const getCrashReport = async () => {
     
    try {
      setShowLoader(true);
      setDataLength(null);
      var result = await AdminDashboardCrashCtrlr.getAdminV2VehicleCrashSingle(
        locationParams.rideId,        
        locationParams.userId,
        locationParams.divisionId,        
      );

      var tableData = [];

      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
     
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          for (let i = 0; i < resultJSONData.length; i++) {
            var userApp = resultJSONData[i];             

            var userDetail = ValueFormat.parseAsObject(userApp.userDetail);

            var firstName =  ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(userDetail.firstName)) || "-";

            var username = ValueFormat.parseAsString(userDetail.username) || "-";

            var userStatus = ValueFormat.parseAsString(userDetail.userStatus) || "-";

            var deviceType = ValueFormat.parseAsString(userDetail.deviceType) || "-";

            var vehicleType = ValueFormat.parseAsString(userDetail.vehicleType) || "-";

            var createdAtToTimeZone = ValueFormat.parseAsString(userDetail.createdAtToTimeZone) || "-";

            var rideName =
              ValueFormat.parseAsString(userApp.deviceName) !== null &&
                ValueFormat.parseAsString(userApp.deviceName) !== undefined
                ? ValueFormat.parseAsString(userApp.deviceName)
                : "-";
                
            var distanceInKm = ValueFormat.parseAsFloat(userApp.distanceInKm,2);

            var durationInMin = ValueFormat.parseAsFloat(userApp.durationInMin,2);

            var userid = ValueFormat.parseAsString(userDetail.userId);

            var deviceId = ValueFormat.parseAsString(userApp.deviceId);

            var errorDataList = ValueFormat.parseAsArray(userApp.errorDataList);       
            
            var userData =  ValueFormat.parseAsObject(userApp.userDetail);

            var totalCrashCount = 0;

            let lastAccidentTypeMILD = 0;

            let lastAccidentTypeMedium = 0;

            let lastAccidentTypeSevere = 0;

            let lastAccidentSeverityValue = 0;

            let lastAccidentAtToTimeZone = "-";

            let lastAccidentTypeName = "-";

            let lastLocationDeviceData = "-";

            let lastCity = "-";

            let lastState = "-";

            let lastZipCode = "-";

            let lastLatitude = "-";

            let lastLongitude = "-";

            let deviceActive = "-";

            let scoreValidationStatus = "-";

            let calculatedDrivingScore = "-";

            let riskScore = "-";

            let calculatedBehaviourScore = "-";

            let category = "-";

        for (let i = 0; i < errorDataList.length; i++) {
      
        
          
          if (errorDataList[i].accidentType === "MILD_ACCIDENT") {
            
            lastAccidentTypeMILD++;
            lastAccidentTypeName = "Mild"

          } else if(errorDataList[i].accidentType === "MEDIUM_ACCIDENT"){

            lastAccidentTypeMedium++;
            lastAccidentTypeName = "Medium"

          }else if(errorDataList[i].accidentType === "SEVERE_ACCIDENT"){

            lastAccidentTypeSevere++;
            lastAccidentTypeName = "Severe"

          }

          lastAccidentSeverityValue = errorDataList[i]?.accidentSeverityValue;
          lastAccidentAtToTimeZone  = errorDataList[i]?.accidentAtToTimeZone;
          lastLocationDeviceData    = errorDataList[i]?.deviceDataAddressField?.location;
          lastCity                  = errorDataList[i]?.deviceDataAddressField?.city;
          lastState                 = errorDataList[i]?.deviceDataAddressField?.state;
          lastZipCode               = errorDataList[i]?.zipCode;
          lastLatitude              = errorDataList[i]?.latitude;
          lastLongitude             = errorDataList[i]?.longitude;
          deviceActive              = errorDataList[i]?.deviceActive || "-";
          scoreValidationStatus     = errorDataList[i]?.scoreValidationStatus || "-";
          calculatedDrivingScore    = errorDataList[i]?.calculatedDrivingScore || "0";
          riskScore                 = errorDataList[i]?.riskScore || "0";
          calculatedBehaviourScore  = errorDataList[i]?.calculatedBehaviourScore || "0";
          category                  = errorDataList[i]?.category || "-";
        }






        let deviceTypeTemp ="";
        if (userData.deviceType === "ANDROID") {
          deviceTypeTemp = "Android"
        } else if (userData.deviceType === "ANDROID_SDK"){
          deviceTypeTemp = "Android SDK"
        } else if (userData.deviceType === "IOS") {
          deviceTypeTemp = "iOS"
        } else if (userData.deviceType === "IOS_SDK"){
          deviceTypeTemp = "iOS SDK"
        } else  {
          deviceTypeTemp = "N/A"
        }
            
            

            var locationDetails = {
              address: "-",
              zipCode: "-",
              latitude: "",
              longitude: "",
              location: "-",
            };

            userApp.accidentKeyValues.forEach((item) => {
              if (item.key === "address")
                locationDetails.address =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "zipCode")
                locationDetails.zipCode =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "latitude")
                locationDetails.latitude =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "longitude")
                locationDetails.longitude =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "location")
                locationDetails.location =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
            });

            // var totalCounts = ValueFormat.parseAsInt(userApp.totalCount);
            
            // setTotalPageCount(Math.ceil(totalCounts / pageSize));

            // setNextShow(totalCounts - index > pageSize);

            var crashDatadetails = {
              firstName: firstName,
              username: username,
              userStatus: userStatus,
              deviceType: deviceType,
              vehicleType: vehicleType == "FOUR_WHEELER" ? "4W" : vehicleType == "TWO_WHEELER" ? "2W" : "-",
              createdAtToTimeZone : createdAtToTimeZone,
              rideName: rideName,
              distanceInKm:distanceInKm,
              durationInMin:durationInMin,
              lastAccidentSeverityValue: ValueFormat.parseAsFloat(lastAccidentSeverityValue,2),
              lastAccidentAtToTimeZone: UtilDateTime.formatDateInt(lastAccidentAtToTimeZone),
              lastAccidentAtToTime: formatTime(lastAccidentAtToTimeZone),
              lastLocationDeviceData:lastLocationDeviceData,
              lastState:lastState,
              lastCity:lastCity,
              lastZipCode:lastZipCode,
              lastLatitude:lastLatitude,
              lastLongitude:lastLongitude,
              lastAccidentTypeName: lastAccidentTypeName,            
              lastAccidentTypeMILD:lastAccidentTypeMILD,
              lastAccidentTypeMedium: lastAccidentTypeMedium,
              lastAccidentTypeSevere:lastAccidentTypeSevere,     
              deviceActive:deviceActive,
              scoreValidationStatus:scoreValidationStatus,
              calculatedDrivingScore:calculatedDrivingScore,
              riskScore:riskScore,
              calculatedBehaviourScore:calculatedBehaviourScore,
              category:category,
              totalCrashCount:(lastAccidentTypeMILD + lastAccidentTypeMedium + lastAccidentTypeSevere),           
              device: deviceTypeTemp,
              ...locationDetails,             
              userid: userid,
              deviceId: deviceId
            };
            tableData.push(crashDatadetails);
            setApiData(tableData);
          }
        }
          } else {       
            setDataLength(0);
            // console.log("Status failed");
          }
        } catch (errMes) {
          // console.log("Crash Report Catch Error: ", errMes);
        } finally {
          setShowLoader(false);
        }

        // if (index == 0) {
        //   setPrevShow(false);
        // } else if (index > 1) {
        //   setPrevShow(true);
        // }

      };

  useEffect(() => {
    if(locationParams.rideId && locationParams.userId){
      getCrashReport();
    }
    getRiskAlertsDataList1();
    // topRowItems();
    
  }, [locationParams]);




  useEffect(() => {
    const platform = new window.H.service.Platform({
      apikey: "pAuj6jqBlpXZUMzd30mtgujRlfWV2jA6qZJPF3JP5is",
    });

    const defaultLayers = platform.createDefaultLayers();

    const map = new window.H.Map(
      document.getElementById("map-container"),
      defaultLayers.vector.normal.map.setMin(3),
      {
        zoom: 17,
        pixelRatio: window.devicePixelRatio || 2,
        center: { lat: 0, lng: 0 }, // Initial center coordinates (e.g., set to your default location)
        heading: 180,
        tilt: 40,
      }
    );

    const ui = window.H.ui.UI.createDefault(map, defaultLayers);
    const behavior = new window.H.mapevents.Behavior(
      new window.H.mapevents.MapEvents(map)
    );

    // Check if errorDataWithinRange is defined and not empty
    if (errorDataWithinRange && errorDataWithinRange.length > 0) {
      errorDataWithinRange.forEach(({ errorEntry, dataWithinRange }) => {
        const lineString = new window.H.geo.LineString();

        // Filter out all ERROR_DATA entries except the last one
        const lastErrorData = dataWithinRange.reduce((last, current) => {
          return current.category === "ERROR_DATA" ? current : last;
        }, null);

        // console.log(lastErrorData,"lastErrorData");

        // Process data points based on index and category
        dataWithinRange.slice(-5).forEach((point, index) => {
          const { latitude, longitude, category } = point;
          // console.log("latitude: ",latitude + " " + "longitude: ",longitude);

          // Determine if the index is even or the category is "ERROR_DATA"
          if (category !== "ERROR_DATA" || point === lastErrorData) {
            lineString.pushLatLngAlt(latitude, longitude);
          try {
            if (latitude && longitude) {
              lineString.pushLatLngAlt(parseFloat(latitude), parseFloat(longitude));
            } else {
              console.error(`Invalid coordinates: ${latitude}, ${longitude}`);
            }
          } catch (e) {
            // console.log("Lat &Long error: ",e );
          }

            let imageFileName =
              category === "ERROR_DATA" ? "accidentCrash.svg" : "carInfo.svg";
            const svgMarkup = require(`../../assets/images/${imageFileName}`);
            const icon = new window.H.map.Icon(svgMarkup, {
              size: { w: 30, h: 40 },
            });

            // Create a marker and add it to the map
            const marker = new window.H.map.Marker(
              { lat: parseFloat(latitude), lng: parseFloat(longitude) },
              { icon }
            );

            const tooltipContent = `
              ${category === "ERROR_DATA" ? "<h5>Crash</h5>" : ""}
              <div style="display: flex; flex-direction: row; align-items: center; width:350px;">
                <img src="${svgMarkup}" style="width: 15px; height: 25px; margin-right:5px;" />
                <div style="margin-top: 5px; text-align: center; font-size:12px">
               
                  <strong>Risk:</strong> ${
                    point.risk || "N/A"
                  }${" "}<strong>Speed:</strong> ${
              point.speed || "N/A"
            } km/h ${" "}<strong>Speed Limit:</strong> ${
              point.speedLimit === "0.00" ? "40.00" : point.speedLimit || "N/A"
            } km/h<br />
                </div>
              </div>
            `;

            // Create an info bubble for the marker
            let bubble = new window.H.ui.InfoBubble(
              { lat: parseFloat(latitude), lng: parseFloat(longitude) },
              { content: tooltipContent }
            );

            // ui.addBubble(bubble, true);
            marker.setData(tooltipContent);
            // Add event listener to open infoBubble on marker tap
            marker.addEventListener(
              "tap",
              (evt) => {
                bubble = new window.H.ui.InfoBubble(evt.target.getGeometry(), {
                  content: evt.target.getData(),
                });
                ui.addBubble(bubble);
              },
              false
            );

            map.addObject(marker);
          }
        });
        if (lineString.getPointCount() > 0) {
        // Add the polyline for the route outline to the map
        const routeOutline = new window.H.map.Polyline(lineString, {
          style: {
            lineWidth: 8,
            strokeColor: "black",
            lineTailCap: "arrow-tail",
            lineHeadCap: "arrow-head",
          },
        });

        var routeArrows = new window.H.map.Polyline(lineString, {
          style: {
            lineWidth: 8,
            fillColor: "white",
            strokeColor: "#ffff",
            lineDash: [0, 1],
            lineTailCap: "arrow-tail",
            lineHeadCap: "arrow-head",
          },
        });

        var routeLine = new window.H.map.Group();
        routeLine.addObjects([routeOutline, routeArrows]);

        //       // Add the polyline to the map
        map.addObject(routeLine);

        map.getViewModel().setLookAtData({
          zoom: 17,
          tilt: 40,
          heading: 180,
          bounds: routeLine.getBoundingBox(),
        });
        // Set the map's center to the last point in dataWithinRange
        const lastDataPoint = dataWithinRange[dataWithinRange.length - 1];
        const avgLatitude = parseFloat(lastDataPoint.latitude);

        const avgLongitude = parseFloat(lastDataPoint.longitude);
        map.setCenter({ lat: avgLatitude, lng: avgLongitude });
      } else {
        console.error("lineString is empty, no polyline will be created.");
      }
    });
    }

    return () => {
      map.dispose(); // Clean up when the component unmounts
    };
  }, [errorDataWithinRange]);

  // Calculate the risk color based on the risk value
  const getRiskColor = (risk, violationRemarks) => {
    if (violationRemarks === "Vehicle crashed") {
      return risk < 50 ? "rgb(237, 115, 115)" : "rgb(224, 13, 13)";
    }
  
    if (risk > 90) {
      return "red";
    } else if (risk > 70 && risk <= 90) {
      return "#FFBF00";
    } else {
      return "#19543e";
    }
  };

  const filterUniqueEntries = (data) => {
    const uniqueEntries = [];
    const visitedLocations = new Set();

    data.forEach((value) => {
      const locationKey = `${value.latitude}-${value.longitude}`;
      if (!visitedLocations.has(locationKey)) {
        visitedLocations.add(locationKey);
        uniqueEntries.push(value);
      }
    });

    // console.log(uniqueEntries, "filterUniqueEntriesfilterUniqueEntries");
    return uniqueEntries;
  };

  const deviceNameHandle = (deviceName) => {

    if(deviceName == "ACTIVE"){
      return "Valid"
    }else{
      return "In Valid"
    }
  }

  const deviceActiveHandle = (deviceName) => {

    if(deviceName == "ACTIVE"){
      return "Active"
    }else if(deviceName == "INACTIVE" || deviceName == "IN_ACTIVE"){
      return "In Active"
    }else{
      return "-"
    }
  }

  const TableStyle = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      border: "0.2px",
      margin: "0px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: "white",
      position: "sticky",
      height: "20px",
      fontWeight: 600,
      fontSize: "12px",
    },
    tbody: {
      display: "block",
      height: "250px",
      overflow: "auto",
    },
  };

  

  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Row className="mt-4">
          <Col sm={6} className="sub_heading">
            <h5 className="heading">Crash Details</h5>
          </Col>
          <Col sm={6}>
            <div className="d-flex justify-content-end">
              <div className="pb-2 d-flex">
                {/* <ViewPdf topRow={topRow} errorDataWithinRange={errorDataWithinRange} /> */}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {/* {(topRow || []).map((widget, key) => (
            <Col xl={2} md={6} key={key}>
              <Card className="card-h-100">
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs={12}>
                      <span
                        data-tooltip-id={key + 1}
                        className="text-muted mb-3  d-block text-truncate sub_heading"
                      >
                        {widget.title}
                        <ReactTooltip
                          id={key + 1}
                          place="top"
                          content={widget.title}
                          style={{ background: "black", zIndex: 1 }}
                        />
                      </span>
                      <h4 className="mb-3 sub_heading">
                        {widget.type === "CATEGORY" ? (
                          <span
                            data-tooltip-id={widget.title}
                            style={{ fontSize: "16px" }}
                          >
                            {widget.overallData}
                          </span>
                        ) : (
                          <span
                            data-tooltip-id={widget.title}
                            className="counter-value"
                          >
                            <CountUp
                              start={0}
                              end={widget.overallData}
                              decimals={widget.type === "OTHERALERTS" ? 2 : 0}
                              decimal="."
                              duration={1}
                            />
                          </span>
                        )}
                        <span style={{ color: "#2ab57d", fontSize: "16px" }}>
                          {widget.overallDataPostFix}
                        </span>
                        {widget.postFix}
                      </h4>
                    </Col>
                  </Row>
                  <div className="text-nowrap body_text">
                    <span>
                      {widget.currentYearValue}
                      <span data-tooltip-id={`tooltip-cpp-${key}`}>
                        {widget.bottomValue}
                      </span>
                    </span>
                    <span className="ms-1 text-muted font-size-13"></span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))} */}
          <Col className="col-12 mt-5">
            <CardBody style={{ padding: "0px",marginTop:"-10px" }}>
              <Row>
                <Col xl={12} md={12} sm={12}>
                  <div style={{position:"relative", maxHeight:490, overflowY:"auto", borderCollapse:"collapse"}}>
                    <Table bordered style={TableStyle.table}>
                      <thead style={{ position: "sticky" }}>
                        <tr>
                          <th
                            className="text-nowrap cardHeaderForDBRDFV"
                            style={{
                              ...TableStyle.th,
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            User Details
                          </th>
                          <th colSpan={2}
                            className="text-nowrap cardHeaderForDBRDFV"
                            style={{
                              ...TableStyle.th,
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            Score Details
                          </th>
                          <th
                            className="text-nowrap cardHeaderForDBRDFV"
                            style={{
                              ...TableStyle.th,
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            Ride Details
                          </th>

                          <th
                          colSpan={2}
                            className="text-nowrap cardHeaderForDBRDFV"
                            style={{
                              ...TableStyle.th,
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            Crash Count  
                            
                            
                          </th>

                        

                          <th
                          colSpan={7}
                            className="text-nowrap cardHeaderForDBRDFV"
                            style={{
                              ...TableStyle.th,
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                          Last Crash Details
                          </th>
                          
                          


                        

                          
                        </tr>
                      </thead>

                      <tbody>
                      {dataLength > 0 ? (
                          (apiData || []).map((apiData, index) => (
                            <tr  key={index} >
                              <td style={{ fontSize: "10px", verticalAlign: "middle", }} >
                                <div style={{ display: "flex", }} >
                                  <div style={{ display: "flex", justifyContent: "space-between", }} >
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={UserNameIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "12px", fontSize: "11px", }} >
                                    
                                        <span style={{ fontSize: "11px", color: "#5156BE", marginBottom: "0px", fontWeight: 700, cursor: "default", }} >
                                          {apiData.firstName}
                                        </span>
                                  
                                    </div>
                                  </div>
                                  <div style={{ marginRight: "4px", marginLeft: "4px", marginTop: "2px", }} >
                                    |
                                  </div>
                                  <div>
                                    <p style={{ fontSize: "11px", color: "#000000", marginBottom: "0px", fontWeight: 700, cursor: "pointer", marginTop: "2px", }} >
                                      {apiData.username}
                                      </p>
                                  </div>
                                  <div>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    >
                                      {deviceActiveHandle(apiData.userStatus)}
                                    </button>
                                  </div>
                                </div>                     

                                <div style={{ marginTop: "10px", display: "flex", }} >
                                  <div style={{ display: "flex", }} >
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    
                                      {(apiData.deviceType == "ANDROID") ? (
                                        <img src={andIconNew} alt="icons2"
                                          style={{ height: "25px", marginLeft: "-4px" }}
                                        />
                                      ) : (
                                        <img src={iosIconNew} alt="icons2"
                                          style={{ height: "25px", marginLeft: "-4px" }}
                                        />
                                      )}                                    
                                      
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                      Device
                                    </div>
                                  </div>

                                  <div style={{ display: "flex", marginLeft: "15px", }} >
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={FTwheelerIcon} alt="icons2"
                                        style={{ height: "25px", marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                      {apiData.vehicleType}
                                    </div>
                                  </div>                      
                                </div>

                                <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between", }} >                    
                                  <div style={{ display: "flex", }} >
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ActiveDateIcon} alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", fontWeight: 400, fontSize: "11px", }} >
                                      <div  style={{ display: "flex", justifyContent: "center",}}>
                                      <p style={{ marginBottom: "0px" }}>Act. Date</p>
                                      <p style={{ marginBottom: "0px", whiteSpace: "nowrap", marginLeft: "5px"  }}
                                      >
                                      {UtilDateTime.formatDateInt(apiData.createdAtToTimeZone)}
                                      </p>
                                      </div>
                                    </div>
                                  </div>
                                
                                </div>
                              </td>

                              <td className="text-nowrap"
                                style={{ textAlign: "center", fontSize: "13px", verticalAlign: "middle", borderRight: "0px", fontWeight: 700, }}
                                dangerouslySetInnerHTML={{
                                  __html: `${parseInt(apiData.calculatedDrivingScore)}`
                                }}
                              />

                              <td  style={{ textAlign: "center", fontSize: "10px", verticalAlign: "middle", borderLeft: "0px", }}
                                // data-tooltip-id={`ETVN${index}`}
                              >
                              
                                <div style={{ display: "flex", }} >
                                  <div style={{ display: "flex", }} >
                                    <div style={{ display: "flex" }}>
                                      {/* <img src={UBIAnticipationNew} alt="icons2" style={{ height: "25px" }} /> */}
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", color:"#000" }} >
                                      Risk 
                                      </div>
                                      <div style={{ marginLeft: "45px", fontSize: "11px", }} >
                                      {parseInt(apiData.riskScore)}
                                      </div>
                                    </div> 
                                  </div>
                                </div>

                                <div style={{ display: "flex", marginTop: "10px",}} >
                                  <div style={{ display: "flex", }} >
                                    <div style={{ display: "flex" }}>
                                      {/* <img src={UBIAnticipationNew} alt="icons2" style={{ height: "25px" }} /> */}
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", color:"#000" }} >
                                        Behavior 
                                      </div>
                                      <div style={{ marginLeft: "22px", fontSize: "11px", }} >
                                      {parseInt(apiData.calculatedBehaviourScore)}
                                      </div>
                                    </div> 
                                  </div>
                                </div>

                                {/* <div style={{display:"flex", marginTop:"5px"}}>
                                  <button type="button" className="btn btn-sm" style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, width:"100px"}} >
                                              {apiData.scoreValidationStatus}
                                  </button>
                                </div> */}

                              </td>

                              <td style={{ textAlign: "center", fontSize: "10px", verticalAlign: "middle", }}
                                // data-tooltip-id={`ETVN${index}`}
                              >
                              
                              <div style={{ display: "flex", justifyContent: "space-between", }} >
                                  <div style={{ display: "flex", }} >
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={i4} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "12px", fontSize: "11px", }} >  
                                    
                                      <p style={{ fontSize: "11px", marginBottom: "0px", cursor: "pointer", }} >
                                        {apiData.rideName}
                                      </p>    
                                    </div>
                                    <div>&nbsp;&nbsp;
                                      <button type="button" className="btn btn-sm " style={{ backgroundColor: apiData.deviceActive?.trim().toUpperCase() === "ACTIVE" ? "#6713BA" :"#D10003" , padding: "3px", fontSize: "8px", fontWeight: 600, color:"#fff" }} >
                                          {deviceNameHandle(apiData.deviceActive)}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ display: "flex", marginTop: "10px", justifyContent: "space-between", }} >
                                  <div style={{ display: "flex", }} >
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={locationIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "12px", fontSize: "11px", }} >                            
                                        <p style={{ fontSize: "11px", marginBottom: "0px", cursor: "default", }} >
                                          {apiData.distanceInKm} Kms <span style={{ fontSize: "11px", marginBottom: "0px", fontWeight:600}}  >|</span>  {apiData.durationInMin} Mins
                                        </p>                           
                                    </div>
                                  </div>
                                </div>
                                <div style={{ display: "flex", marginTop: "10px", justifyContent: "space-between", }} >
                                  <div style={{ display: "flex", }} >
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={apiData.category?.trim().toUpperCase() === "RIDE_DRIVER" ? driverTypeIcon : passengerTypeIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "12px", fontSize: "11px", }} >                            
                                        <p style={{ fontSize: "11px", marginBottom: "0px", cursor: "default", }} >
                                        {apiData.category?.trim().toUpperCase() === "RIDE_DRIVER" ? "Driver" : "Passenger"}
                                        </p>                           
                                    </div>
                                  </div>
                                </div>

                                  {/* <div style={{ display: "flex", justifyContent:"center", marginTop: "10px",  }} >
                                    <div style={{ display: "flex", justifyContent:"center", backgroundColor:"red"}} >
                                      <button type="button" className="btn btn-sm" style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, width:"100px"}} >
                                            {apiData.category}
                                        </button>
                                    </div>
                                  </div> */}
                              </td>

                              <td className="text-nowrap"
                                style={{ textAlign: "center", fontSize: "13px", verticalAlign: "middle", borderRight: "0px", fontWeight: 700, }}
                                dangerouslySetInnerHTML={{
                                  __html: `${apiData.totalCrashCount}`
                                }}
                              />

                              <td style={{ textAlign: "left", fontSize: "10px", verticalAlign: "middle", borderLeft: "0px", }} >
                                <div style={{ display: "flex" }}>                        
                                  <div style={{ display: "flex" }}>
                                      {/* <img src={UBIAnticipationNew} alt="icons2" style={{ height: "25px" }} /> */}
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", color:"#D1C600" }} >
                                        Mild
                                      </div>
                                      <div style={{ marginLeft: "22px", fontSize: "11px", }} >
                                      {apiData.lastAccidentTypeMILD}
                                      </div>
                                </div>                     
                                
                                </div>
                                <div style={{ display: "flex", marginTop: "10px", }} >
                                
                                    <div style={{ display: "flex" }}>
                                      {/* <img
                                        src={UBISelfConfidence}
                                        alt="icons2"
                                        style={{ height: "25px" }}
                                      /> */}
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          marginLeft: "7px",
                                          fontSize: "11px",
                                          color:"#F14A21"
                                          
                                        }}
                                      >
                                      Medium
                                      </div>
                                      <div
                                    style={{
                                    
                                      marginLeft: "5px",
                                      fontSize: "11px",
                                    }}
                                  >
                                  {apiData.lastAccidentTypeMedium}
                                  </div>
                                    </div>
                                  
                                
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "10px",
                                    // justifyContent:"space-between" 
                                  }}
                                >
                                  
                                    
                                    
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          marginLeft: "8px",
                                          fontSize: "11px",
                                          color:"#CB0206"
                                          

                                        }}
                                      >
                                        Severe
                                      </div>
                                      <div
                                    style={{                            
                                      fontSize: "11px",
                                      marginLeft:"12px"
                                    }}
                                  >
                                    {apiData.lastAccidentTypeSevere}
                                  </div>
                                  
                                  
                                  
                                </div>
                              </td>

                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "10px",
                                  verticalAlign: "middle",
                                  borderRight: "0px",
                                  fontWeight: 700,
                                  fontSize: "11px",
                                }}
                              >
                                <div
                                  style={{ fontSize: "13px" }}
                                >
                                { parseInt(apiData.lastAccidentSeverityValue)}g
                                  </div>
                              </td>
                              <td
                                className="text-nowrap"
                                style={{
                                  textAlign: "left",
                                  fontSize: "10px",
                                  verticalAlign: "middle",
                                  borderLeft: "0px",
                                }}
                              >
                              
                                
                                    
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          marginLeft: "8px",
                                          fontSize: "11px",
                                        }}
                                      >
                                      {apiData.lastAccidentTypeName}
                                      </div>                          
                                
                                
                              
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "10px",
                                  }}
                                >
                                  
                                  
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          marginLeft: "8px",
                                          fontSize: "11px",
                                        }}
                                      >
                                        {apiData.lastAccidentAtToTimeZone}
                                      </div>                         
                                
                                
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "10px",
                                  }}
                                >
                                
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          marginLeft: "9px",
                                          fontSize: "11px",
                                        }}
                                      >
                                      {apiData.lastAccidentAtToTime}
                                      </div>
                                    

                                
                                </div>

                              
                              </td>
                              <td
                              colSpan={4}
                                className="text-nowrap "
                                style={{
                                  textAlign: "left",
                                  fontSize: "10px",
                                  verticalAlign: "middle",
                                  fontSize: "11px",
                                  
                                }}
                              >
                                <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationImageCrash}
                                        alt="icons2"
                                        style={{ height: "25px", marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        color: "#000000",
                                        fontWeight: 400,
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.lastCity}
                                    </div>
                                  </div>   

                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "5px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={AddressImageCrash}
                                        alt="icons2"
                                        style={{ height: "25px", marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        color: "#000000",
                                        fontWeight: 400,
                                        fontSize: "11px",
                                      }}
                                    >
                                    {`${apiData.lastLocationDeviceData}, ${apiData.lastCity}, ${apiData.lastState}`}
                                    </div>
                                  </div>  

                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "5px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={ZipcodeImageCrash}
                                        alt="icons2"
                                        style={{ height: "25px", marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        color: "#000000",
                                        fontWeight: 400,
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.lastZipCode}
                                    </div>
                                  </div>                                            
                              </td>

                            
                              <td
                                style={{
                                  textAlign: "left",
                                  fontSize: "10px",
                                  verticalAlign: "middle",
                                  fontSize: "11px",
                                }}
                              >
                              <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <img
                                        src={LatImage}
                                        alt="icons2"
                                        style={{ height: "25px" }}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          marginLeft: "8px",
                                          fontSize: "11px",
                                        }}
                                      >
                                        Lat
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      marginLeft: "14px",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {apiData.lastLatitude}
                                  </div>
                                </div>

                                <div style={{ display: "flex",marginTop:"10px"}}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <img
                                        src={LongImage}
                                        alt="icons2"
                                        style={{ height: "25px" }}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                          marginLeft: "8px",
                                          fontSize: "11px",
                                        }}
                                      >
                                        Long
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      marginLeft: "8px",
                                      fontSize: "11px",
                                    }}
                                  >
                                    {apiData.lastLongitude}
                                  </div>
                                </div>
                              </td>
                            </tr>
                        ))
                        ) : dataLength === 0 ? ( 
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "black",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.DATA_NOT_AVAILABLE}
                              </div>
                            </td>
                          </tr>
                        ) : dataLength === null ? (
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.FETCHING_DATA}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>
        <Card className="mt-2">
          <CardHeader className="px-3 py-4 cardHeader">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <h5 className="mb-0">Crash Visualization </h5>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div id="map-container" style={{ width: "100%", height: "60vh" }} />
          </CardBody>
        </Card>

        <div>
          <div>
            {/* <h4>Crash Details</h4> */}
            {errorDataWithinRange.map(
              ({ errorEntry, dataWithinRange }, index) => (
                <Card key={index} className="mt-0" >
                  <CardHeader className="cardHeader" style={{display:"flex", flexDirection:"row", gap:20, alignItems:"center",}}>
                    <div style={{display:"flex", alignContent:"center", gap:5, justifyItems:"center"}}>                      
                      <img src={crashIcon} alt="crash_tow_icon" style={{ height: "20px" }}/>
                      <p style={{height: "0px"}}>Crash: <span style={{fontWeight:600, fontSize:"13px"}}>Crash {index + 1}</span></p>
                    </div>
                    <div style={{display:"flex", alignContent:"center", gap:5, justifyItems:"center"}}>
                      <img src={crashIntensityIcon} alt="crash_intensity_icon" style={{ height: "25px" }}/>
                      <p style={{height: "0px"}}> Intensity of crash: <span style={{fontWeight:600, fontSize:"13px"}}>
                        {errorEntry.accidentType == "MILD_ACCIDENT" ? "Mild Crash" : errorEntry.accidentType == "MEDIUM_ACCIDENT" ? "Medium Crash"
                        : errorEntry.accidentType == "SEVERE_ACCIDENT" ? "Severe Crash" : null }</span>
                      </p>
                    </div>
                    <div style={{display:"flex", alignContent:"center", gap:5, justifyItems:"center"}}>
                      <img src={crashValueIcon} alt="crash_tow_icon" style={{ height: "25px" }}/>
                      <p style={{height: "0px"}}>Crash Value: <span style={{fontWeight:600, fontSize:"13px"}}>{errorEntry.accidentSeverityValue ? parseInt(errorEntry.accidentSeverityValue)  : "-"}g</span></p>
                    </div>
                    <div style={{display:"flex", alignContent:"center", gap:5, justifyItems:"center"}}>
                      <img src={contactIcon} alt="crash_tow_icon" style={{ height: "25px" }}/>
                      <p style={{height: "0px"}}>Emergency Contact: <span style={{fontWeight:600, fontSize:"13px"}}>{errorEntry.emergencyContactNumber}</span></p>
                    </div>
                  </CardHeader>
                  {/* <CardBody className="table-responsive"> */}
                    <Table bordered style={TableStyle.table}>
                      <thead >
                        <tr >
                          <th style={{ whiteSpace: "nowrap", width: "10%", paddingRight: "40px", }}>Events Timing</th>
                          <th style={{ whiteSpace: "nowrap", width: "10%", paddingRight: "40px",}}> Events Geo Location </th>
                          <th style={{ whiteSpace: "nowrap", width: "24%", paddingRight: "110px", }}>Event Location</th>
                          <th style={{ whiteSpace: "nowrap", width: "10%", paddingRight: "40px", }}>Event Distance & Speed</th>
                          <th style={{ whiteSpace: "nowrap", width: "14%", paddingRight: "110px", }}>Events Remarks</th>
                        </tr>
                      </thead>
                      <tbody >
                        {filterUniqueEntries(dataWithinRange).map(

                          (value, i, array) => {
                            return ( 
                            <tr key={i} >
                              <td style={{ textAlign: "left", alignContent: "center" }} >
                                {value.category === "ERROR_DATA" && 
                                  <div style={{ display: "flex", }}>
                            <button type="button" className="btn btn-sm" style={{ backgroundColor: apiData.deviceActive?.trim().toUpperCase() === "ACTIVE" ? "#6713BA" :"#D10003" , padding: "3px", fontSize: "11px", fontWeight: 600, color:"#fff" }} >
                                Crash
                            </button>
                                </div>
                                }


                                <div style={{ display: "flex", }}>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <div style={{ display: "flex" }}>
                                      <img src={calendarIcon} alt="icons2" style={{ height: "25px" }} />
                                    </div>
                                  </div>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", }} >
                                    {UtilDateTime.formatDateInt(value.time).split(" ")[0]}
                                  </div>
                                </div>

                                <div style={{ display: "flex", marginTop:"10px"}}>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <div style={{ display: "flex" }}>
                                      <img src={clockIcon} alt="icons2" style={{ height: "25px" }} />
                                    </div>
                                  </div>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", }} >
                                    {UtilDateTime.formatDateTime(value.time).split(" ")[1]}
                                  </div>
                                </div>
                              </td>

                              <td style={{ textAlign: "left", alignContent: "center" }} >
                                  <div style={{display:"flex",  alignItems: "center", gap:8}}>
                                  <img src={LatImage} alt="icons2" style={{ height: "25px" }}/>
                                  <p style={{height: "0px", fontSize:"11px"}}>Lat <span style={{marginLeft: "8px"}}>{value.latitude}</span></p>
                                  </div>
                                  <div style={{display:"flex",  alignItems: "center", gap:8, marginTop: "10px"}}>
                                  <img src={LongImage} alt="icons2" style={{ height: "25px" }}/>
                                  <p style={{height: "0px", fontSize:"11px"}}>Long <span style={{marginLeft: "8px"}}>{value.longitude}</span></p>
                                  </div>
                              </td>

                              <td className="text-nowrap "
                                style={{ textAlign: "left", fontSize: "10px", verticalAlign: "middle", fontSize: "11px", }} >
                                <div style={{ display: "flex", marginLeft: "5px", }} >
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={locationImageCrash} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                    {errorEntry?.deviceDataAddressField?.city}
                                    </div>
                                  </div>   

                                  <div style={{ display: "flex", marginLeft: "5px", marginTop: "10px", }} >
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={AddressImageCrash} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", color: "#000000", fontWeight: 400,  fontSize: "11px", }} >
                                    {`${errorEntry?.deviceDataAddressField?.location}, ${errorEntry?.deviceDataAddressField?.city}, ${errorEntry?.deviceDataAddressField?.state}`}
                                    </div>
                                  </div>  

                                  <div style={{ display: "flex", marginLeft: "5px", marginTop: "10px", }}  >
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ZipcodeImageCrash} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <div  style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                    {errorEntry?.zipCode }
                                    </div>
                                  </div>                                            
                              </td>

                              <td style={{ textAlign: "left", fontSize: "10px", fontSize: "11px", }} >
                              <div style={{ display: "flex" }}>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <div style={{ display: "flex" }}>
                                      <img src={actualSpeedIcon} alt="icons2" style={{ height: "25px" }} />
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", fontWeight:700}} >
                                      Actual Speed
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "14px", fontSize: "11px", }} >
                                  {value.speed}
                                  </div>
                                </div>

                                <div style={{ display: "flex",marginTop:"10px"}}>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <div style={{ display: "flex" }}>
                                      <img src={speedLimitICon} alt="icons2" style={{ height: "25px" }} />
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", fontWeight:700}} >
                                      Speed Limit
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", }} >
                                    {value.speedLimit === "0.00"
                                    ? "40.00"
                                    : value.speedLimit}
                                  </div>
                                </div>

                                <div style={{ display: "flex",marginTop:"10px"}}>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <div style={{ display: "flex" }}>
                                      <img src={distanceIcon} alt="icons2" style={{ height: "25px" }} />
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", fontWeight:700}} >
                                      Distance
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "8px", fontSize: "11px", }} >
                                    {value.kilometer}
                                  </div>
                                </div>
                              </td>

                              <td style={{ width: "34%" }}> {value.violationRemarks}  </td>
                            </tr>
                          )}
                        )}
                      </tbody>
                    </Table>
                  {/* </CardBody> */}
                </Card>
              )
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HereMapTest;
