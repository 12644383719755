import React, { lazy, Suspense } from 'react';

import HereMapTest from './testHereMap';
export default function GoogleMapCrashView() {
  return (
    <div>
         <Suspense fallback={<div>Loading...</div>}>
     
      <HereMapTest/>
      </Suspense>
    </div>
  )
}
