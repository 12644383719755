import React from "react";
import ReactApexChart from "react-apexcharts";
import ReactEcharts from "echarts-for-react";
const LineColumnAreaTwo = ({ DistributionData }) => {
  
  const option = {

    // tooltip: {
    //   show: true,
    //   trigger: 'item',
    //   formatter: function (parms) {
    //     const customData =
    //       '<div class="arrow_box" style="padding:10px;">' +
    //       "<span>" +
    //       "Distribution % " +
    //       ": " +
    //       parms.data.value +
    //       "%" +
    //       "</span>" 
    //       "</div>"
    //     return customData;
    //   },
    // },
    grid: { bottom: 40, left: 75, right: 50, top: 30 },
    xAxis: {

      name: "Risk Count vs Distribution %",
      type: 'value',
      nameTextStyle:{
        color:"#0095FF",
      },
      axisLabel: {
        fontWeight: 400,
        fontSize: "10px",
        fontFamily: "Segoe UI",
        color:"#0095FF",
      },
      nameLocation: "middle",
      nameGap: 30,
      splitLine: {
        show: false
      },
    },
    yAxis: {
      // name: "Risk distribution count",
      type: 'category',
      data: DistributionData.xaxis,
      nameLocation: "middle",
      // nameGap: 90,
      
      inverse: true,
      nameTextStyle:{
        color:"#0095FF",
        fontSize: "10px",
        fontWeight: 400,
        fontFamily: "Segoe UI",
      },
      axisLabel: {
        fontWeight: 400,
        fontSize: "10px",
        fontFamily: "Segoe UI",
        fontColor: "#f1f1f1",
        letterSpacing:"30px",
        color:"#0095FF"
      
      },
      splitLine: {
        show: false,
      },
    },
    series: {
      name: "Risk Level",
      type: "bar",
      data: DistributionData.data,
      barWidth: 10,
      label: {
        show: true,
        position: 'right',
        textStyle: {
          fontSize: 9,
          fontWeight: 'bold',
        },
        formatter: function (params) {
          return `${params.value}%`; 
        },
      },
      itemStyle: {
        color: function (dataPointIndex) {
          // console.log("dataPointIndex: " + JSON.stringify(dataPointIndex))
          if (dataPointIndex.dataIndex >= 3) {
            return "#19543E";
          } else if (dataPointIndex.dataIndex >= 1 && dataPointIndex.dataIndex <= 2) {
            return "#ffbf00";
          } else {
            return "#FD0100";
          }

        },
      },
      // markPoint: {
      //   data: [
      //     { type: 'max',
      //      name: 'Max', 
      //      itemStyle: {
      //       color: 'red'
      //      },
           
      //     },
      //     { type: 'min',
      //      name: 'Min',
      //      itemStyle: {
      //       color: 'darkGreen'
      //      },
      //     }
      //   ]
      // },

    },
  };

  return (
    <ReactEcharts
      option={option}
      type="bar"
      style={{ height: "345px" }}
    />
  );
};
export default LineColumnAreaTwo;
