export const apiConfig = {
  // API_ROOT_URL: "https://dev-services-app.motivai.tech/api",
  API_ROOT_URL: "https://uat-services-app.motivai.tech/api",
  // API_ROOT_URL: "https://beta-services-app.motivai.tech/api", 
  // API_ROOT_URL: "https://services-app.motivai.tech/api",
  API_VERSION_1: "/v1",
  API_VERSION_2: "/v2",
  STATIC_SESSION: "202120212021202120212021",
  COMPANY_ID: "comp00000000000000000001", 
}; 
