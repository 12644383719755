import { apiConfig } from "../../config/Config.js";
import { apiEndPoint, apiType, apiStatus } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
// import { GlobalConst } from "./../../constant/GlobalConstant.js";
 
import UserSessionController from "../UserSession/UserSessionController.js";
import {
    SC_COMPANY_ID,
    SC_DIVISION_ID,
    SC_MODULE_ID,
    SC_LOGIN_USER_ID,
    SC_TIME_ZONE_ID,
    SC_LANGUAGE_ID,
    SC_GROUP_ID,
    SC_COUNTRY_ID,
} from "../../constant/StorageConstant.js";
 
// import { apiStatus, apiType, apiAction } from './../../constant/api_constant.js';
 
let userController = {   
   
    async createUserRegistration(phone, password,usernameType,deviceType,countryId,twoFA,dpass,addFirstName,vehicleType) {
        var companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
        var divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
        var userId = UserSessionController.getLocalSessionValue(SC_LOGIN_USER_ID);
        var moduleId = UserSessionController.getLocalSessionValue(SC_MODULE_ID);
        if (
            companyId.length > 0 &&
            divisionId.length > 0 &&
            userId.length > 0 &&
            moduleId.length > 0
        ) {
            var reqPayload = {
                companyId: companyId,
                countryId:countryId,
                divisionId: divisionId,
                userId: userId,
                moduleId: moduleId,
                status: apiStatus.REGISTERED,
                type: apiType.USER,
                category: apiType.USER,
                userCollections: [{                  
                    category: "USER",
                    deviceType: deviceType,
                    firstName: addFirstName,  
                    mfaPrimaryTypeEnable: twoFA,  
                    password: password,      
                    username: phone,  
                    usernameType:usernameType,
                    defaultPassword: dpass,
                    vehicleType: vehicleType             
                }, ],
            };
            return await ApiService.callPOST(
                apiConfig.API_VERSION_1,
                apiEndPoint.USER_REGISTER,
                1,
                reqPayload
            );
        } else {
            return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
        }
    },
 
    async createBulkUserRegistration(usernameType, twoFa, formData) {
        try {
            const companyId = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
            const divisionId = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
            const userId = UserSessionController.getLocalSessionValue(SC_LOGIN_USER_ID);
           
            if (companyId && divisionId && userId) {
                const reqPayload = {
                    companyId: companyId,
                    divisionId: divisionId,
                    userId: userId,
                    usernameType: usernameType,
                    type: apiType.USER,
                    mfaPrimaryTypeEnable: twoFa,
                };
                // console.log("setting file in controller", formData.get('file'));
                return await ApiService.encryptCallPOST(
                    apiConfig.API_VERSION_1,
                    apiEndPoint.USER_BULK_REGISTRATION,
                    1,
                    reqPayload,
                    formData
                );
            } else {
                return { status: "FAILED", error: "Required session values are missing", data: [] };
            }
        } catch (err) {
            console.error("Error in createBulkUserRegistration:", err);
            return { status: "FAILED", error: "An error occurred", data: [] };
        }
    }, 

};
 
export default userController;