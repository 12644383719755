import React from "react";
import { Nav, NavItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./UserDashboardSubMenu.css";

const UserAdminDashboardSubMenu = () => {
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "left", alignItems: "center", }} >
        <h5 style={{ marginTop: "5px",fontSize:"16px" }} className="heading">User Dashboard</h5>&nbsp;&nbsp;&nbsp;
        <div>
          <Nav justified pills>
            
          </Nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserAdminDashboardSubMenu;
