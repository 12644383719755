import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { SC_COMPANY_NAME, SC_USER_LOGIN_TYPE } from "../../constant/StorageConstant.js";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
import SidebarSuperAdminContent from "./SidebarSuperAdminContent.js";
import SidebarSuperAdminClientContent from "./SidebarSuperAdminClientContent.js";

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          
          {props.type !== "condensed" && localStorage.getItem(SC_USER_LOGIN_TYPE) == "USER" ? <SidebarContent />  : localStorage.getItem(SC_USER_LOGIN_TYPE) == "SUPER_ADMIN" ? <SidebarSuperAdminContent /> :  localStorage.getItem(SC_USER_LOGIN_TYPE) == "SUPER_ADMIN" ? <SidebarSuperAdminClientContent /> : "" }
         
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withTranslation()(Sidebar));
