export const apiHdrDefValue = {
    APPLICATION_JSON: "application/json",
    REFERRED_BY: "WEB",
    FORM_URLENCODED: "application/x-www-form-urlencoded",
    MULTIPART_FORM_DATA: "multipart/form-data",
};

export const apiHttpStatus = {
    SC_200: 200,
    SC_201: 201,
    SC_202: 202,
    SC_400: 400,
    SC_403: 403,
    SC_412: 412,
    SC_424: 424,
    SC_440: 440,
    SC_404: 404
};

export const errorMessage = {
    ELSE_ERROR: "Request process failed due to some technical issues. Please contact Admin"
}

export const apiDefaultValue = {
    OFFSET: 0,
    LIMIT: 100
};

export const apiAction = {
    YES: "YES",
    NO: "NO"
};

export const apiStatus = {
    REGISTERED: "REGISTERED"
};

export const apiEndPoint = {
    HEALTH: "/health",
    USER: "/user",
    USER_REGISTER: "/users/registration",
    USER_SESSION: "/userSession",
    DEVICE: "/device",
    QUERY: "/query",
    DASHBOARD: "/dashboard",
    DIVISION: "/division",
    GROUP: "/group",
    COMPANY_PREFERENCE: "/companyPreference",
    DEVICE_DATA: "/deviceData",
    FILE: "/file",
    FEEDBACK: "/userFeedback",
    USER_PREFERENCE: "/userPreference",
    RAWDATA:"/rawData",
    USER_SECRECT:"/userSecret",
    SEARCH:"/search",
    REPORT:"/report",
    ORDER:"/order",
    DIVISION_PREFERENCE:"/divisionPreference",
    USER_QUESTIONS:"/user/questions",
    USER_AUTHENTICATION: "/userSession/authentication",
    COUNTRY:"/country",
    USER_SECRET_VERIFICATION:"/userSecretVerification",
    USER_SECRET_SECURITY_QUESTIONS:"/userSecret/securityQuestion",
    USER_BULK_REGISTRATION: "/user/bulkRegistration",
    MANUAL_SMSNOTIFICATION: "/manual/smsNotification",
    TRIGGER:"/trigger"
};

export const apiType = {
    USER: "USER",
    USER_SESSION: "USER_SESSION",
    FEEDBACK: "FEEDBACK",
    REPORT_PROBLEM:"REPORT_PROBLEM"
};

export const apiCategory = {
    USER_PASSWORD: "USER_PASSWORD"
};