import React from "react";
import { NavItem, Nav } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./Submenu.css";

const AdminDashboardSubMenu = () => {
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          margin: 5,
          textWrap:"nowrap"
        }}
      >
        <h5 className="heading mb-0">Admin Dashboard</h5>&nbsp;
        <p style={{position:"relative", top:0, fontSize:"12px", height:"0px"}}>{"(For recent ride summary, Please select any one of the CD / CW / CM options from the filter)"}</p>
       
      </div>
    </React.Fragment>
  );
};

export default AdminDashboardSubMenu;
